import './App.css';
import { useContext, useMemo } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary';
import { 
  makeStyles,
  CssBaseline,
  Button,
  Toolbar,
  AppBar,
  Box,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Divider,
  Typography,
  Link as MLink
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  Menu as MenuIcon,
  Apps as ViewerIcon,
  TableChart as RangeGroupsIcon,
  FitnessCenter as TrainerIcon,
  Brightness7 as DarkModeIcon,
  Brightness6 as LightModeIcon,
} from '@material-ui/icons';
import logo from "./assets/logo.png";
import Viewer from "./pages/Viewer";
import Trainer from './pages/Trainer';
import RangeGroups from "./pages/RangeGroups";
import ErrorFallback from './components/ErrorFallback';
import AuthContext from "./providers/AuthContext";
import { ConfigProvider } from "./providers/ConfigContext";
import { getLoginUrl } from './util/auth';
import { useState } from 'react';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { useConfig, useDarkMode } from './hooks';
import { CONFIG_VALUE_ENABLED } from './util/config';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
    minHeight: "calc(100vh - 120px)",
  },
  logo: {
    marginRight: 10,
  },
  logoContainer: {
    flexGrow: 1
  },
  appBar: {
    paddingTop: 4,
    minHeight: 68,
    boxShadow: "none",
    background: theme.palette.type === "dark" ? theme.palette.background.default : "#000",
    borderBottom: "1px solid rgba(255, 255, 255, 0.23)",
    color: "#fff"
  },
  list: {
    width: 250,
  },
  footer: {
    textAlign: "center",
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }
}));

function App() {
  const classes = useStyles();
  const { sessionExpired } = useContext(AuthContext);
  const [darkModeEnabled, setDarkModeEnabled] = useDarkMode();
  const theme = useMemo(
    () => createMuiTheme({
      palette: {
        type: darkModeEnabled ? 'dark' : 'light',
        primary: {
          main: darkModeEnabled ? "#f7931a" : "#000",
        },
      },
      overrides: {
        MuiTooltip: {
          tooltip: {
            fontSize: "1em",
          }
        }
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 640,
          md: 1130,
          lg: 1280,
          xl: 1920,
        },
      },
    }),
    [darkModeEnabled]
  );
  
  
  const toggleDarkMode = () => setDarkModeEnabled(!darkModeEnabled);

  return (
    <ConfigProvider>
      <MuiThemeProvider theme={theme}>
        <Router>
          <div className={classes.root}>
            <CssBaseline />
            <Navigation onToggleDarkMode={toggleDarkMode} darkModeEnabled={darkModeEnabled}/>
            <main className={classes.content}>
              {
                sessionExpired && <Box mb={1}>
                  <Alert severity="warning" action={<Button href={getLoginUrl()}>Refresh</Button>}>
                    Your session has expired. Refresh the viewer in any browser tab to continue your session
                  </Alert>
                </Box>
              }
              <Routes/>
            </main>
            <Box component="footer" className={classes.footer}>
              <Divider/>
              <Box m={1} >
                <Typography variant="caption">
                  Designed and developed by <MLink href="mailto:rapidpokertools@gmail.com">Rapid Poker Tools</MLink>
                </Typography>
              </Box>
            </Box>
          </div>
        </Router>
      </MuiThemeProvider>
    </ConfigProvider>
  );
}

const Routes = () => {
  const [config] = useConfig();
  const experimentalFeaturesEnabled = config.experimentalFeatures === CONFIG_VALUE_ENABLED;

  const handleError = (err) => {
    console.error(err);
  }

  return <Switch>
    <Route path="/trainer">
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
        <Trainer />
      </ErrorBoundary>
    </Route>
    {
      experimentalFeaturesEnabled && <Route path="/groups">
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
        <RangeGroups />
      </ErrorBoundary>
    </Route>
    }
    <Route path="/">
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
        <Viewer />
      </ErrorBoundary>
    </Route>
  </Switch>
}

const Navigation = ({ darkModeEnabled, onToggleDarkMode }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);
  const [config] = useConfig();
  const experimentalFeaturesEnabled = config.experimentalFeatures === CONFIG_VALUE_ENABLED;
  
  const drawer = (
    <List className={classes.list}>
      <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
        <ListItemText primary={"Dynamic Preflop Viewer"} primaryTypographyProps={{ variant: "subtitle1", style: { fontWeight: 800 } }} />
      </ListItem>
      <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
        <ListItemIcon><ViewerIcon /></ListItemIcon>
        <ListItemText primary={"Viewer"} />
      </ListItem>
      <ListItem button component={Link} to="/trainer" onClick={handleDrawerToggle}>
        <ListItemIcon><TrainerIcon /></ListItemIcon>
        <ListItemText primary={"Trainer"} />
      </ListItem>
      {
        experimentalFeaturesEnabled && <ListItem button component={Link} to="/groups" onClick={handleDrawerToggle}>
          <ListItemIcon><RangeGroupsIcon /></ListItemIcon>
          <ListItemText primary={"Range Groups"} />
        </ListItem>
      }
    </List>
  );

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <div className={classes.logoContainer}>
          <a href="https://zenith.poker" target="_blank" rel="noreferrer">
            <img src={logo} className={classes.logo} alt="zenith logo" height={36} />{" "}
          </a>
        </div>
        <Hidden smUp implementation="css">
          <IconButton style={{color: "white"}} onClick={onToggleDarkMode}>
            {
              darkModeEnabled ? <DarkModeIcon/> : <LightModeIcon/>
            }
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            variant="temporary"
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown>
          <Tooltip title={"Toggle Dark Mode"}>
            <IconButton style={{color: "white"}} onClick={onToggleDarkMode}>
              {
                darkModeEnabled ? <DarkModeIcon/> : <LightModeIcon/>
              }
            </IconButton>
          </Tooltip>
          <Button component={Link} to="/" color="inherit">Viewer</Button>
          <Button component={Link} to="/trainer" color="inherit">Trainer</Button>
          {
            experimentalFeaturesEnabled && <Button component={Link} to="/groups" color="inherit">Range Groups</Button>
          }
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

export default App;
