import { FOLD, actionIdToParts } from "./gameTree";
const seedrandom = require("seedrandom");

const white = "#FFFFFF"
const black = "#000000"

export const evToBB = ev => ev/2000;

export const decisionsToHandScore = (decisions) => decisions.length > 0 ? decisions.map(i => i.score).reduce((a, b) => a+b, 0)/Math.max(decisions.length, 1) : 100;

export const comboArrayToObject = (arr = []) => arr.reduce((acc, {_row, ...rest}) => ({
  ...acc,
  [_row]: rest
}), {})

export const comboFrequenciesToPercentages = comboFrequencies => {
  const total = Object.values(comboFrequencies).reduce((a, b) => a + b, 0);
  const percentages = Object.entries(comboFrequencies).map(([action, value]) => [action, total === 0 ? 0 : (value / total) * 100]);
  return Object.fromEntries(percentages);
}

const getComboCount = (combo) => {
  if (combo.endsWith("o")) return 12;
  if (combo.endsWith("s")) return 4;
  return 6;
}

export const getOverallFrequencies = frequencies => {
  // Exclude any combos not in our range
  const frequenciesInRange = Object.fromEntries(
    Object
    .entries(frequencies)
    .filter(([combo, comboData]) => isComboInRange(comboData))
  );
  let totalCombosInRange = 0;
  const overall = Object
    .entries(frequenciesInRange)
    .reduce((acc, [combo, comboData]) => {
      // Keep a tally of how many combos we have in total
      const comboCount = getComboCount(combo);
      totalCombosInRange += comboCount;
      // Convert the freq data to %
      Object
        .entries(comboData)
        .forEach(([action, freq]) => {
          // Add the combo count, * by the raw freq the action is taken for this combo to the running total for the action
          acc[action] = (comboCount * freq) + (acc[action] ? acc[action] : 0)
        })
      return acc;
    }, {})
  // Divide the summed raw freq by the total combos in range * 100
  // Then convert to percentage
  return Object.fromEntries(
    Object
    .entries(overall)
    .map(([action, freq]) => [action, (freq/totalCombosInRange*100)])
    .map(([action, freq], idx, src) => {
      return [action, freq/(src.map(i => i[1]).reduce((a, b) => a + b, 0)) * 100]
    })
  )
}

export const getOverallEV = (frequencies, evData) => {
  // Exclude any combos not in our range
  const frequenciesInRange = Object.fromEntries(
    Object
    .entries(frequencies)
    .filter(([combo, comboData]) => isComboInRange(comboData))
  );
  let totalCombosInRange = {};
  const overall = Object
    .entries(frequenciesInRange)
    .reduce((acc, [combo, comboData]) => {
      const comboCount = getComboCount(combo);
      Object
        .entries(comboData)
        .forEach(([action, freq]) => {
          // For each action keep a tally of how many combos take that action
          if (freq > 0) totalCombosInRange[action] = (comboCount * (freq/100)) + (totalCombosInRange[action] || 0)
          // Add the combo count * by the freq the action (as decimal) is taken * by th EV of the action for this combo to the running total for the action
          acc[action] = ((comboCount * freq/100) * evData[combo][action]) + (acc[action] ? acc[action] : 0)
        })
      return acc;
    }, {})
  // Divide the summed EV by the total combos which took a particular action
  return Object.fromEntries(
    Object
    .entries(overall)
    .map(([action, ev]) => [action, ev/totalCombosInRange[action]])
  )
}

const isComboInRange = (comboData) => comboData && !Object.values(comboData).every(i => i=== 0);

const getRawFrequencyMask = rawFrequencySum => {
  const notInRangeFreq = Math.round((1-rawFrequencySum)*100); // Round to deal with precision errors
  return notInRangeFreq <= 0
    ? ""
    : `linear-gradient(to top, black 0% ${notInRangeFreq}%, transparent ${notInRangeFreq}% 100%), `
}

const rawFreqReducer = (a,b) => a+b

export const frequencyComboStyler = (combos = {}, actions=[], showRawFreqMask = false, normalizeRawFreqMask = false, overrides=() => ({})) => combo => {
  let bgString = "";
  let color = black;
  const comboData = combos[combo];
  if (actions.length === 0) bgString = white;
  else if (!isComboInRange(comboData)) {
    bgString = black;
    color = white;
  }
  else {
    bgString = "linear-gradient(to left";
    const percentages = Object.values(comboFrequenciesToPercentages(comboData));
    let sum = 0;
    actions.forEach((a, idx) => {
      const frequency = percentages[idx];
      if (frequency > 0) {
        sum += frequency;
        bgString += `, ${a.color} ${sum - frequency}%, ${
          a.color
        } ${sum}%`;
      }
    });
    bgString += ")";
  }
  if (showRawFreqMask && isComboInRange(comboData)) {
    const rawFrequencySum = Object.values(combos[combo]).reduce(rawFreqReducer, 0);
    const maxRawFrequencySum = Math.max(...Object.values(combos).map(i => Object.values(i).reduce(rawFreqReducer, 0)));
    const finalRawFrequencySum = normalizeRawFreqMask ? Math.min(1, rawFrequencySum/maxRawFrequencySum) : rawFrequencySum
    if (finalRawFrequencySum < .3) color = "white";
    bgString = getRawFrequencyMask(finalRawFrequencySum) + bgString
  }
  return {
    background: bgString,
    color,
    ...overrides(combo),
  };
}

export const sampleComboStyler = (seed, highlightedAction) => (combos = {}, actions = [], showRawFreqMask = false, normalizeRawFreqMask = false, overrides=() => ({})) => {
  const rng = seedrandom(seed);
  if (!combos || actions.length === 0) return (combo) => ({
    backgroundColor: white,
    color: black,
    ...overrides(combo),
  })

  return (combo) => {
    let color = black;
    const comboAsPercentages = comboFrequenciesToPercentages(combos[combo]);
    if (!isComboInRange(combos[combo])) return {backgroundColor: black, color: white, ...overrides(combo)}
    const action = getRandomAction(comboAsPercentages, rng, combo);
    const parts = actionIdToParts(action);
    let bgString = (actions.find((a, i) => {
        return a.id === action;
    }) || {color: white}).color;
    if (showRawFreqMask && isComboInRange(combos[combo])) {
      const rawFrequencySum = Object.values(combos[combo]).reduce(rawFreqReducer, 0);
      const maxRawFrequencySum = Math.max(...Object.values(combos).map(i => Object.values(i).reduce(rawFreqReducer, 0)));
      const finalRawFrequencySum = normalizeRawFreqMask ? Math.min(1, rawFrequencySum/maxRawFrequencySum) : rawFrequencySum
      if (finalRawFrequencySum < .3) color = "white";
      bgString = getRawFrequencyMask(finalRawFrequencySum) + bgString
    }
    return {
      background: bgString,
      boxSizing: "border-box",
      boxShadow: highlightedAction === action && parts.action !== FOLD ? "0 0 1px 3px #ffffff inset" : "none",
      color, 
      ...overrides(combo)
    }
  }
};

// use an RNG to select action according to frequencies
const getRandomAction = (comboFrequencies, rng) => {
  // Get the action names
  const actionNames = Object.keys(comboFrequencies);
  // Get the pct freq for each action * 100 for precision
  const actionValues = Object.values(comboFrequencies).map(i => i * 100);
  // Sum multiplied action values
  const sumOfFrequencies = actionValues.reduce((a, b) => a+b, 0)
  let randomInt = getRandomInt(
    0,
    sumOfFrequencies,
    rng
  );
  // Keep deducting the multiplied values from the random int. when it's below zero, that's our action
  const idx = actionValues.findIndex((f) => {
    randomInt -= f
    return randomInt <= 0;
  });
  // Handle not found
  return idx === -1 ? undefined : actionNames[idx]
};

export const getRandomInt = (min = 1, max = 100, rng = Math.random) => {
  min = Math.ceil(Math.max(1, min));
  max = Math.floor(max);
  return Math.floor(rng() * (max - min + 1)) + min;
};

export const rotateArray = (arr, toIndex) => arr.slice(toIndex, arr.length).concat(arr.slice(0, toIndex))

export const toFixedIfRequired = (num, places=2) => {
  const replace = new Array(places).fill("0");
  const re = new RegExp(`[.,]${replace.join("")}$`,"g");
  return num.toFixed(places).replace(re, "")
};

export const groupByKey = (arr, key) => {
  return arr
    .reduce((acc, obj) => {
      const group = obj[key] === undefined ? "" : obj[key]; 
      return Object.assign(acc, { [group]:( acc[group] || [] ).concat(obj)})
    }, {});
}

export const allSettledSequential = async (tasks, fn) => {
  const results = [];

  for (let i=0; i < tasks.length; i++) {
    try {
      results.push({
        status: "fulfilled",
        value: await fn(tasks[i])
      })
    } catch (err) {
      results.push({
        status: "rejected",
        reason: err.message
      })
    }
  }
  return results;
}

export const arrayToCumulativeSumArray = arr => arr.map((sum => value => sum += value)(0));
export const getCumulativeFreq = freqs => freqs.reduce((a,b) => a+b, 0);

export const getRngBoundary = freqsObj => {
  const freqs = Object.values(freqsObj);
  const actions = Object.keys(freqsObj);
  const vals = freqs.map((i, idx, src) => {
    if (i === 0) return "-";
    if (i < 1 && idx === 0) return "1";

    const lastActionIdx = findLastIndex(src, j => j > 0);
    const previousTakenIdx = findLastIndex(src.slice(0, idx+1), j => j > 0);
    let lowerBound = Math.floor(previousTakenIdx === -1 ? 1 : getCumulativeFreq(freqs.slice(0, previousTakenIdx))) + 1;
    const upperBound = idx === lastActionIdx ? 100 : Math.ceil(getCumulativeFreq(freqs.slice(0, idx+1)));
    if (lowerBound >= upperBound) return `${upperBound}`;
    return `${lowerBound}-${upperBound}`
  });

  return action => {
    const idx = actions.indexOf(action);
    return vals[idx]
  }
}

export const findLastIndex = (array, predicate) => {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array))
        return l;
  }
  return -1;
}
