import React, { useEffect, useState, useContext } from "react"
import { useErrorHandler } from "react-error-boundary";
import { useHotkeys } from "react-hotkeys-hook";
import { HandMatrix } from "@holdem-poker-tools/hand-matrix"
import {
  makeStyles,
  Box,
  Grid,
  Tooltip,
  FormControl,
  Button,
  ButtonGroup,
  IconButton,
  Typography,
  InputLabel,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Popover,
  Snackbar,
  useTheme,
  useMediaQuery,
  ListSubheader
} from "@material-ui/core";
import {
  Refresh,
  Settings,
  ArrowBack,
  FileCopy,
  Undo,
  InfoOutlined,
  TableChart as RangeGroupsIcon,
} from "@material-ui/icons";
import {
  Alert
} from "@material-ui/lab";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import AuthContext from "../providers/AuthContext";
import GameTree from "../components/GameTree";
import StrategyTable from "../components/StrategyTable";
import { BasicAccessAlert, NoAccessAlert } from "../components/AccessAlert";
import LoadingPlaceholder from "../components/Loading";
import NextOptions from "../components/NextOptions";
import APIClient from "../api";
import {
  sampleComboStyler,
  frequencyComboStyler,
  getRandomInt,
  comboFrequenciesToPercentages,
  getOverallFrequencies,
  getOverallEV,
  groupByKey,
} from "../util";
import {
  actionIdToString,
  gameTreeToPotDetails,
  orderOfAction,
  actionSorter,
  actionInBB,
  gameTreePathToNodeId,
} from "../util/gameTree";
import { CONFIG_VALUE_ENABLED, SAMPLE_MODE_HINT_ACTION_HOVER } from "../util/config";
import { getActionColor } from "../util/colors";
import { ExpiredTokenError } from "../util/auth";
import ViewerConfigDialog from "../components/ViewerConfigDialog";
import ViewerControls from "../components/ViewerControls";
import CopyRangeDialog from "../components/CopyRangeDialog";
import { useConfig } from "../hooks";
import { addRangeToGroup, getRangeGroups } from "../groups";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: 120,
    width: "100%"
  },
  formControlInline: {
    minWidth: 120,
    width: "100%"
  },
  select: {
    width: "100%"
  },
  group: {
    marginLeft: 7,
    paddingLeft: 7,
    borderLeft: `1px dashed grey`,
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row"
  },
  control: {
    marginLeft: 5,
    marginRight: 5
  },
  dialogBody: {
    padding: 20,
    paddingTop: 0
  },
  matrixConfigTitle: {
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  rangeCheckboxLabel: {
    width: "100%"
  },
  loader: {
    position: "fixed",
    width: "100%",
    textAlign: "center",
    bottom: 5,
    left: 0
  },
  popover: {
    padding: theme.spacing(2),
  }
}));

function Viewer() {
  const initialScenario = {
    rangeSet: "",
    position: "",
  };
  const { sessionExpired, setSessionExpired } = useContext(AuthContext);
  const handleError = useErrorHandler();
  const classes = useStyles();
  // App State
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down("xs"));
  const [loading, setLoading] = useState(true);
  const [loadedRangeSets, setLoadedRangeSets] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);
  const [showCopyRangeDialog, setShowCopyRangeDialog] = useState(false);
  const [showConfigDialog, setShowConfigDialog] = useState(false);
  const popupState = usePopupState({ variant: 'popover', popupId: 'rangeInfo' });
  // Source Data
  const [rangeSets, setRangeSets] = useState([]);
  const [positions, setPositions] = useState([]);
  // Config
  const [config] = useConfig();
  // Scenario
  const [scenario, setScenario] = useState(initialScenario);
  // Current View
  const [seed, setSeed] = useState(0);
  const [data, setData] = useState({});
  const [gameTreePath, setGameTreePath] = useState([]);
  const [selectedCombo, setSelectedCombo] = useState(undefined);
  const [focusedAction, setFocusedAction] = useState(undefined);

  const frequencyModeEnabled = config.frequencyMode === CONFIG_VALUE_ENABLED;
  const weightedModeEnabled = config.weightedMode === CONFIG_VALUE_ENABLED;
  const normalizeWeightedMode = config.normalizeWeightedMode === CONFIG_VALUE_ENABLED;
  const experimentalFeaturesEnabled = config.experimentalFeatures === CONFIG_VALUE_ENABLED;

  const groupedRangeSets = groupByKey(rangeSets, "group");

  useEffect(() => {
    setLoading(true);
    APIClient.getRangeSets()
      .then(rangeSets => {
        setRangeSets(rangeSets)
        rangeSets.length && setScenario(e => ({ ...e, rangeSet: rangeSets[0].id }))
      })
      .catch(err => {
        console.warn(err);
        setHasErrored(true);
        handleError(new Error("Unable to load range sets!"));
      })
      .finally(() => {
        setLoading(false);
        setLoadedRangeSets(true);
      })
  }, [setLoading, setRangeSets, setScenario, setLoadedRangeSets, setHasErrored, handleError]);

  useEffect(() => {
    if (scenario.rangeSet !== "") {
      setLoading(true);
      APIClient.getRangeSetPositions(scenario.rangeSet)
        .then(positions => {
          setPositions(positions)
          setScenario(e => ({ ...e, position: positions[0] }))
        })
        .catch(err => {
          console.warn(err);
          setHasErrored(true);
          handleError(new Error("Unable to load range set positions!"));
        })
        .finally(() => setLoading(false))
    }
  }, [scenario.rangeSet, setScenario, setPositions, setLoading, setHasErrored, handleError]);

  useEffect(() => {
    // Check we have the required info to start
    const { rangeSet, position } = scenario;
    if (rangeSet !== "" && position !== "" && !hasErrored) {
      setLoading(true);
      const rangeSetInfo = rangeSets.find(i => i.id === scenario.rangeSet)
      const currentNode = gameTreePathToNodeId(gameTreePath);
      console.debug(`Retrieving data for ${currentNode === "" ? "root" : currentNode}`)
      APIClient.getNodeData(rangeSet, position, currentNode, rangeSetInfo.version)
        .then((nodeData) => {
          // If terminal is true then we've reached the end of this game tree path
          if (nodeData.terminal === true) return setData(e => ({ ...e, terminal: true, nextOptions: [] }));
          const pd = gameTreeToPotDetails(gameTreePath, rangeSetInfo)
          // Store the retrieved data for this node
          setSeed(getRandomInt(1, 1000));
          setData({
            ...nodeData,
            actions: nodeData.frequency && Object.values(nodeData.frequency).length > 1 ? Object
              .keys(Object.values(nodeData.frequency)[0])
              .map((action, idx, src) => {
                return {
                  id: action,
                  name: actionIdToString(action),
                  color: getActionColor(action, src, config.palette),
                  size: actionInBB(action, pd)
                }
              }) : []
          })
        })
        .catch(err => {
          console.warn(err);
          if (err instanceof ExpiredTokenError) {
            setSessionExpired(true);
          }
          setHasErrored(true);
          handleError(new Error("Unable to load range node data!"));
        })
        .finally(() => setLoading(false));
    }
  }, [config.palette, scenario, setData, setLoading, setSeed, gameTreePath, rangeSets, hasErrored, handleError, setSessionExpired, setHasErrored]);

  useEffect(() => {
    setSeed(getRandomInt(1, 1000));
  }, [config.frequencyMode]);

  const handleGameTreeNextActionChange = (val) => {
    setSelectedCombo(undefined);
    setGameTreePath(gt => gt.concat(val));
  }

  const handleScenarioChange = (newScenario) => {
    setScenario(newScenario)
    setGameTreePath([]);
    setData({});
    setSelectedCombo(undefined);
  }

  const handleTraverseGameTree = (idx) => {
    setGameTreePath(gt => gt.slice(0, idx))
  }

  const handleResample = () => {
    setSeed(getRandomInt(1, 1000));
  }

  const toggleModal = () => setShowCopyRangeDialog(!showCopyRangeDialog);

  const currentRangeSetDetails = scenario.rangeSet && rangeSets.find(i => i.id === scenario.rangeSet);
  const potDetails = currentRangeSetDetails ? gameTreeToPotDetails(gameTreePath, currentRangeSetDetails) : { pot: 0 }
  const styler = frequencyModeEnabled ? frequencyComboStyler : sampleComboStyler(seed, config.sampleModeHinting === SAMPLE_MODE_HINT_ACTION_HOVER && focusedAction);
  const leftToAct = data.nextOptions && orderOfAction(gameTreePath, scenario.position, currentRangeSetDetails.seats);
  const overallFrequencies = (data.frequency && Object.keys(data.frequency)) ? getOverallFrequencies(data.frequency) : {};
  const overallEV = (data.frequency && Object.keys(data.frequency)) ? getOverallEV(data.frequency, data.EV) : {};

  const nextOptions = (data.nextOptions || [])
    .filter(i => !(gameTreePath.length === 0 && i === `${scenario.position}f`))
    .map(i => Array.isArray(i) ? i : [i])
    .concat(gameTreePath.length && leftToAct.length > 2
      ? leftToAct.slice(2).map((i, idx) => leftToAct.slice(0, idx + 2).map(j => `${j}f`))
      : [])
    .sort(actionSorter)

  useHotkeys('0,1,2,3,4,5,6,7,8,9', (e, handler) => {
    if (!loading && nextOptions.length > 0) {
      if (parseInt(handler.key) <= nextOptions.length) {
        const key = parseInt(handler.key);
        handleGameTreeNextActionChange(nextOptions[
          key === 0 ? nextOptions.length - 1 : key - 1
        ]);
      }
    }
  }, {keyup: true}, [loading, data]);

  useHotkeys('backspace', (e, handler) => {
    if (!loading && gameTreePath.length > 0) {
      handleTraverseGameTree(Math.max(0, gameTreePath.length - 1));
    }
  }, {keyup: true}, [loading, gameTreePath]);

  const matrixControlSize = useMediaQuery(theme.breakpoints.down("sm")) ? "small" : "medium";

  if (loading && !scenario.rangeSet && rangeSets.length === 0) return <LoadingPlaceholder />;

  if (!hasErrored && loadedRangeSets && rangeSets.length === 0) return <NoAccessAlert />;

  return (
    <div>
      {
        !hasErrored && loadedRangeSets && rangeSets.length === 1 && <BasicAccessAlert />
      }
      <Box mb={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <div style={{display: "flex"}}>
              <FormControl className={classes.formControlInline}>
                <InputLabel id="range-set-label">Range Set</InputLabel>
                <Select
                  labelId="range-set-label"
                  id="range-set-select"
                  value={scenario.rangeSet}
                  placeholder="Select a scenario"
                  onChange={e => e.target.value && handleScenarioChange({ rangeSet: e.target.value, position: "" })}
                  className={classes.select}
                  disabled={loading || sessionExpired}
                >
                  {
                    Object.entries(groupedRangeSets)
                      .reduce((acc, [group, rangeSets]) => {
                        return acc.concat([
                          <ListSubheader key={group}>{group}</ListSubheader>,
                          ...rangeSets.map(rangeSet => <MenuItem key={rangeSet.id} value={rangeSet.id}>{rangeSet.name}</MenuItem>)
                        ])
                      }, [])
                  }
                </Select>
              </FormControl>
              {
                currentRangeSetDetails?.description?.length > 0 && <div>
                  <IconButton {...bindTrigger(popupState)}>
                    <InfoOutlined/>
                  </IconButton>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}>
                    <Typography style={{padding: 15, maxWidth: 400}}>{currentRangeSetDetails.description}</Typography>
                  </Popover>
                </div>
              }
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControlInline} style={{ paddingTop: compact ? 0 : 12 }}>
              <ButtonGroup fullWidth={true} variant="outlined" color="primary" aria-label="outlined button group">
                {positions.map(position => <Button variant={scenario.position === position ? "contained" : "outlined"} disabled={sessionExpired || scenario.rangeSet === ""} key={position} onClick={() => handleScenarioChange({ ...scenario, position })}>{position}</Button>)}
              </ButtonGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControlInline} style={{ paddingTop: compact ? 0 : 12 }}>
              <Button disabled={sessionExpired} color="primary" variant="outlined" onClick={() => setShowConfigDialog(e => !e)}><Settings /> Viewer Config</Button>
            </FormControl>
            <ViewerConfigDialog
              config={config}
              open={showConfigDialog}
              loading={loading}
              onClose={() => setShowConfigDialog(false)}
            />
          </Grid>
          {
            loading && <div className={classes.loader}>
              <CircularProgress size={20} />
            </div>
          }
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <Typography variant="h6">Game Tree</Typography>
          {nextOptions.length > 0 &&
            <Box mb={1}>
              <NextOptions
                onMouseEnter={setFocusedAction}
                onMouseLeave={() => setFocusedAction(undefined)}
                actions={data.actions}
                leftToAct={leftToAct}
                nextOptions={nextOptions}
                onChange={handleGameTreeNextActionChange}
                disabled={loading || sessionExpired}
                label={"Next Action"}
              />
            </Box>}
          <Box m={1}>
            <Typography>Pot: {potDetails.pot?.toFixed(2)}BB</Typography>
            <GameTree
              disabled={sessionExpired}
              currentRangeSetDetails={currentRangeSetDetails}
              gameTreePath={gameTreePath}
              showEllipsis={nextOptions?.length || false}
              potDetails={potDetails}
              rightContent={
                (node, idx) => (
                  <Tooltip title="Edit Action" aria-label="edit-button">
                    <span>
                      <IconButton disabled={sessionExpired} edge="end" aria-label="edit" onClick={() => handleTraverseGameTree(idx)}>
                        <Undo />
                      </IconButton>
                    </span>
                  </Tooltip>
                )
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={5} xl={4}>
          <Typography variant="h6" className={classes.matrixConfigTitle}>
            Combos
          </Typography>
          <Box mb={1}>
            <ViewerControls/>
            <HandMatrix
              comboStyle={styler(data.frequency, data.actions, weightedModeEnabled, normalizeWeightedMode)}
              colorize={false}
              onSelect={setSelectedCombo}
            />
          </Box>

          {navigator.clipboard && <CopyRangeDialog key={JSON.stringify(data)} frequencyData={data.frequency} actions={data.actions} open={showCopyRangeDialog} onClose={toggleModal} format={config.rangeStringFormat} config={config} />}
          <Box className={classes.controls} mb={1}>
            {navigator.clipboard && <Button size={matrixControlSize} className={classes.control} disabled={!data.frequency} endIcon={matrixControlSize === "small" ? null : <FileCopy />} variant="outlined" color="primary" onClick={toggleModal}>Copy Range</Button>}
            <Button size={matrixControlSize} className={classes.control} disabled={frequencyModeEnabled} endIcon={matrixControlSize === "small" ? null : <Refresh />} variant="outlined" color="primary" onClick={handleResample}>Resample</Button>
            {experimentalFeaturesEnabled && <AddRangeToGroupForm rangeDetails={{gameTreePath, rangeSet: scenario.rangeSet, position: scenario.position, version: currentRangeSetDetails.version}} buttonSize={matrixControlSize}/>}
          </Box>

          <div style={{ textAlign: "center" }}>
            {
              !scenario.rangeSet && <Typography>Please select a range set</Typography>
            }
            {
              scenario.rangeSet && !scenario.position && <Typography>Please select a position</Typography>
            }
          </div>
        </Grid>
        {Object.keys(data).length > 0 && <Grid item xs={12} sm={12} md={4} lg={4} xl={5}>
          {
            !selectedCombo
              ? <div>
                <Typography variant="h6">Overall Strategy</Typography>
                <StrategyTable percentages={overallFrequencies} evData={overallEV} actions={data.actions} />
                <Box mt={1}>
                  <Typography variant="caption">Select a combo to view its Frequencies and EV</Typography>
                </Box>
              </div>
              : <div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <ArrowBack onClick={() => setSelectedCombo(undefined)} />
                  <Typography variant="h6">{selectedCombo} Strategy {`(${Math.min(100, (Object.values(data.frequency[selectedCombo]).reduce((a, b) => a + b, 0) * 100)).toFixed(1)}%)`}</Typography>
                </div>
                <StrategyTable percentages={comboFrequenciesToPercentages(data.frequency[selectedCombo])} evData={data.EV[selectedCombo]} actions={data.actions} />
              </div>
          }
        </Grid>}
      </Grid>
    </div>
  );
}

const AddRangeToGroupForm = ({buttonSize="medium", rangeDetails}) => {
  const classes = useStyles();
  const { sessionExpired } = useContext(AuthContext);
  const [groupAnchorEl, setGroupAnchorEl] = useState(null);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [name, setName] = useState("");
  const [snackbarContent, setSnackbarContent] = useState(undefined);

  useEffect(() => {
    getRangeGroups()
      .then((res) => {
        setGroups(res)
        res.length > 0 && setSelectedGroup(e => e === null ? res[0].id : e)
      });
  }, [groupAnchorEl, setGroups]);

  const handleAddToGroupClick = (event) => {
    setGroupAnchorEl(event.currentTarget);
  };

  const handleCloseAddToGroup = () => {
    setGroupAnchorEl(null);
  };

  const handleAdd = () => {
    const { gameTreePath, position, rangeSet, version } = rangeDetails;
    if (groups.find(i => i.id === selectedGroup).ranges.length < 12) {
      addRangeToGroup(gameTreePath.join("/"), position, rangeSet, version, name, selectedGroup)
        .then(() => setSnackbarContent({ type: "success", text: "Range Added!" }))
        .then(() => handleCloseAddToGroup())
        .catch(handleError)
    } else {
      setSnackbarContent({ type: "error", text: "You cannot add more than 12 ranges to a group" })
    }
  }

  const handleError = (err) => {
    console.error(err);
    setSnackbarContent({ type: "error", text: "Unable to add range" });
  }


  if (groups.length === 0) return <></>
  return (
    <>
      <Snackbar open={snackbarContent !== undefined} autoHideDuration={6000} onClose={() => setSnackbarContent(undefined)}>
        {snackbarContent && <Alert onClose={() => setSnackbarContent(undefined)} severity={snackbarContent.type}>
          {snackbarContent.text}
        </Alert>}
      </Snackbar>
      <Button size={buttonSize} className={classes.control} aria-describedby={Boolean(groupAnchorEl) ? 'simple-popover' : undefined} variant="outlined" color="primary" endIcon={buttonSize === "small" ? null : <RangeGroupsIcon/>} onClick={handleAddToGroupClick}>
        Add to Group
      </Button>
      <Popover
        id={Boolean(groupAnchorEl) ? 'simple-popover' : undefined}
        open={Boolean(groupAnchorEl)}
        anchorEl={groupAnchorEl}
        onClose={handleCloseAddToGroup}
      >
        <Box className={classes.popover}>
          <FormControl className={classes.formControl}>
            <InputLabel id="group-label">Range Group</InputLabel>
            <Select
              labelId="group-label"
              id="group-select"
              value={selectedGroup || ""}
              placeholder="Select a group"
              onChange={e => setSelectedGroup(e.target.value)}
              className={classes.select}
              disabled={sessionExpired || groups.length === 0}
            >
              {groups.map(group => <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField autoComplete="off" fullWidth={true} id="range-group-title" label="Range Name" value={name} onChange={e => setName(e.target.value)}/>
          </FormControl>
          <Button disabled={sessionExpired || name.length === 0} onClick={handleAdd} fullWidth={true}>Add to Group</Button>
        </Box>
      </Popover>
    </>
)
}

export default Viewer;
