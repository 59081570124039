import { comboArrayToObject } from "../util";
import { isExpired, ExpiredTokenError, APIError, getToken } from "../util/auth";
import fetchRetry from "fetch-retry";
import { cardsToComboString, cardStringToCards } from "../util/range";

const fetch = fetchRetry(window.fetch, {
  retries: 1,
  retryDelay: 100,
  retryOn: (attempt, error, response) => {
    if (attempt < 1 && (error !== null || response.status >= 400)) {
      return true;
    }
  }
})

const { REACT_APP_API_URL: API_URL = `${window.location.protocol}//${window.location.host}/api` } = process.env;
const { REACT_APP_AUTH_URL: AUTH_URL = `${window.location.protocol}//${window.location.host}/auth` } = process.env;

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json;charset=UTF-8',
}

const parseResponse = async (resp) => {
  if (!resp.ok) 
    throw new APIError(resp.text());
  return resp.json();
}

const refreshAuthToken = async (refreshToken) => {
  return fetch(`${AUTH_URL}/refresh`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({refreshToken}),
    cache: 'no-cache',
    redirct: "manual",
    retries: 0,
    headers,
    retryOn: () => false
  })
  .then(parseResponse)
}

const getRangeSets = () => {
  return fetch(`${API_URL}/range_sets`, {
    method: "GET",
    mode: "cors",
    headers: {
      ...headers,
      Authorization: `Bearer ${getToken()}`,
    }
  })
    .then(parseResponse)
    .then(data => {
      return data.range_sets.map(range_set => ({
        id: range_set.id,
        name: range_set.name,
        ante: range_set.ante,
        players: range_set.players,
        stack: range_set.stack,
        straddles: range_set.straddles,
        seats: range_set.seats,
        version: range_set.version,
        complete: range_set.complete,
        canTrain: range_set.can_train,
        description: range_set.description,
        group: range_set.group,
      }))
    });
}

const getRangeSetPositions = (rangeSetId) => {
  return fetch(`${API_URL}/range_sets/${rangeSetId}/positions`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${getToken()}`,
    }
  })
    .then(parseResponse)
    .then(data => data.positions);
}

const getNodeData = (rangeSetId, position, nodeId, version="") => {
  let endpoint = `${API_URL}/range_sets/${rangeSetId}/positions/${position}/freqs`;
  const queryString = new URLSearchParams();
  if (nodeId !== "") queryString.append("node_path", nodeId);
  if (version !== "") queryString.append("version", version);
  endpoint += `?${queryString.toString()}`;
  return fetch(endpoint, {
    headers: {
      ...headers,
      Authorization: `Bearer ${getToken()}`,
    }
  })
    .then(parseResponse)
    .then(resp => ({
      nodeId,
      nextOptions: resp.nextOptions,
      frequency: comboArrayToObject(resp.frequency),
      EV: comboArrayToObject(resp.EV),
      terminal: resp.terminal || false
    }));
}

const resyncAccess = () => {
  let endpoint = `${API_URL}/sync`;
  return fetch(endpoint, {
    headers: {
      ...headers,
      Authorization: `Bearer ${getToken()}`,
    },
    method: 'POST'
  })
    .then(parseResponse); 
}

const getNodeActionScores = async (rangeSetId, position, rng, hand, nodeId="", version="") => {
  let endpoint = `${API_URL}/range_sets/${rangeSetId}/positions/${position}/scores`;
  const queryString = new URLSearchParams();
  queryString.append("rng", rng);
  queryString.append("hand", hand);
  if (nodeId !== "") queryString.append("node_path", nodeId);
  if (version !== "") queryString.append("version", version);
  endpoint += `?${queryString.toString()}`;
  return fetch(endpoint, {
    headers: {
      ...headers,
      Authorization: `Bearer ${getToken()}`,
    }
  })
    .then(parseResponse)
    .then(resp => ({
      nodeId,
      nextOptions: resp.scores.map(i => i.action),
      frequency: resp.scores.reduce((acc, next, i) => ({
        ...acc,
        [next.action]: next.freq
      }), {}),
      EV: resp.scores.reduce((acc, next, i) => ({
        ...acc,
        [next.action]: next.EV
      }), {}),
      scores: resp.scores.reduce((acc, next, i) => ({
        ...acc,
        [next.action]: next.score
      }), {}),
      dEVs: resp.scores.reduce((acc, next, i) => ({
        ...acc,
        [next.action]: next.dEVs
      }), {}),
    }));
}

const dealHand = (rangeSetId, position, rangeSubsets, nodeId="") => {
  let endpoint = `${API_URL}/range_sets/${rangeSetId}/positions/${position}/deal`;
  const queryString = new URLSearchParams();
  if (nodeId !== "") queryString.append("node_path", nodeId);
  rangeSubsets.forEach(i => queryString.append("range_subset", i));
  endpoint += `?${queryString.toString()}`;
  return fetch(endpoint, {
    headers: {
      ...headers,
      Authorization: `Bearer ${getToken()}`,
    }
  })
    .then(parseResponse)
    .then(resp => (resp.cards.map(i => ({
      position: i.position,
      cards: cardStringToCards(i.combo),
    }))))
    ;
}

const getActions = (rangeSetId, position, seats, n, nodeId="") => {
  let endpoint = `${API_URL}/range_sets/${rangeSetId}/positions/${position}/actions`;
  const queryString = new URLSearchParams();
  queryString.append("n", n);
  seats.slice(seats.findIndex(i => i.position === position), seats.length).forEach(i => {
    queryString.append("hands", cardsToComboString(i.cards));
    queryString.append("player_positions", i.position);
  });
  if (nodeId !== "") queryString.append("node_path", nodeId);
  endpoint += `?${queryString.toString()}`;
  return fetch(endpoint, {
    headers: {
      ...headers,
      Authorization: `Bearer ${getToken()}`,
    }
  })
    .then(parseResponse);
}

const checkToken = (fn) => (...args) => {
  const token = getToken();
  if (!token) return Promise.reject(new APIError("You must obtain an auth token before calling the API"));
  if (isExpired(token)) return Promise.reject(new ExpiredTokenError("Token Expired"));
  return fn(...args);
}


const APIClient = {
  getRangeSets: checkToken(getRangeSets),
  getRangeSetPositions: checkToken(getRangeSetPositions),
  getNodeData: checkToken(getNodeData),
  getNodeActionScores: checkToken(getNodeActionScores),
  getActions: checkToken(getActions),
  dealHand: checkToken(dealHand),
  resyncAccess: checkToken(resyncAccess),
  refreshAuthToken
}

export default APIClient;
