import {actions, actionIdToParts} from "./gameTree";

const palettes = {
  Standard: ["#FFFFFF","#59d34a","#fcdd05","#FB6467","#B7E4F9","#ba4244","#526E2D","#E762D7","#E89242","#FAE48B","#a0e9ba","#917C5D","#9485c0", "#e8d05f", "#739926", "#dd4100", "#7c09ff", "#ffbea3", "#73fbdc"],
  Pastel: ["#FFFFFF","#8dd3c7","#ffffb3","#bebada","#fb8072","#80b1d3","#fdb462","#b3de69", "#fccde5","#d9d9d9","#bc80bd","#ccebc5","#ffed6f", "#3742ea", "#675f44", "#4795a0", "#eb59dd", "#6f105d", "#bf9848"],
  Phase: ["#FFFFFF","#A8780D", "#DC299C", "#6B79E8", "#199963", "#B96A22", "#D329C4", "#4986D4", "#3B973C", "#C85B39", "#C23DE0", "#2A8FB9", "#688F1B", "#D44A54", "#A953EF", "#1A949E", "#8D830D", "#DB3775", "#8D67F1", "#0E9783", "#A8780D"],
  Isol: ["#FFFFFF","#E839E5", "#0A9B3A", "#C643EB", "#0A9D14", "#A44DF2", "#1E9A04", "#8257F9", "#409504", "#6261F9", "#629003", "#4970DD", "#848B03", "#317FC2", "#A3790B", "#198EA6", "#C16415", "#0A9786", "#E04F1F", "#0A9960", "#FF3A2A"],
  Simpsons: ["#FFFFFF","#FED439", "#709AE1", "#8A9197", "#D2AF81", "#FD7446", "#D5E4A2", "#197EC0", "#FBB5FF", "#46732E", "#71D0F5", "#075149", "#C80813", "#91331F", "#1A9993" ],
  Alternate: ["#FFFFFF", "#08719B", "#08C5C5", "#08EDD8", "#08C173", "#08893F", "#55C408", "#DFE508", "#E07F08", "#CC0808", "#d1a5a5", "#8F5252", "#AF9191", "#739926","#bc80bd","#ccebc5"],
  Rainbow: ["#FFFFFF","#5B0A76", "#08799D", "#08B35E", "#E0D108", "#7D2D2D", "#086371", "#08D872", "#BEE508", "#680909", "#5A07EF", "#08A3A8", "#088D4F", "#E09608", "#8D4E4D", "#082F6C", "#08E8C0", "#73D008", "#A30808", "#BEAEAE", "#0808DA", "#08CDCD", "#098A16", "#E05408", "#9D6E6D", "#0808AA", "#08E9E9", "#40B008", "#D60E08", "#AD8E8E"],
  Fish: ["#FFFFFF","#32CD32","#FFFF00","#00008B","#FFA07A","#F29340","#FF6933","#FF4500","#E00000","#7A0000","#A300A3","#660066"],
  Samurai: ["#FFFFFF","#32CD32","#FFFF00","#FF0000","#FF4500","#7A0000","#A300A3","#135E90","#735F59","#0029E0","#C2BCBC","#FF6933","#FF77FF"]
}

export const getActionColor = (actionId, srcActions, colorPalette="Standard") => {
  const {action} = actionIdToParts(actionId);
  const palette = palettes[colorPalette];
  if (action === actions.f) return palette[0];
  if (action === actions.c) return palette[1];
  if (action === actions.min) return palette[2];
  if (action === actions.j) return palette[3];
  const idx = srcActions.filter(i => actionIdToParts(i).action === actions.r).indexOf(actionId);
  return palette.slice(4)[idx] || getRandomColor();
}

export const colorPalettes = Object.keys(palettes);

const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
  