import React from "react"
import {
  Box,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { CONFIG_VALUE_ENABLED, CONFIG_VALUE_DISABLED, CONFIG_KEYS } from "../util/config";
import { useConfig } from "../hooks";

function ViewerControls() {
  const [config, handleConfigChange] = useConfig();
  const { frequencyMode, weightedMode } = config;

  const onToggle = (configKey) => (e) => {
    handleConfigChange(configKey, e.target.checked ? CONFIG_VALUE_ENABLED : CONFIG_VALUE_DISABLED)
  }

  return (
    <Box mb={1}>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <FormControlLabel
          control={<Switch size="small" checked={weightedMode === CONFIG_VALUE_ENABLED} onChange={onToggle(CONFIG_KEYS.weightedMode)} />}
          label="Weighted Mode"
        />
        <FormControlLabel
          control={<Switch size="small" checked={frequencyMode === CONFIG_VALUE_ENABLED} onChange={onToggle(CONFIG_KEYS.frequencyMode)} />}
          label="Frequency Mode"
        />
      </div>
    </Box>
  );
}


export default ViewerControls;
