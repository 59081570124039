export const scenarios = {
  "100_STD": [
    {
      title: "BB vs All Open (Common Sizes)",
      startNodes: [
        "LJr040/HJf/COf/BUf/SBf",
        "HJr040/COf/BUf/SBf",
        "COr050/BUf/SBf",
        "BUr060/SBf",
        "SBr100"
      ],
      enabledForHero: ["BB"]
    },
    {
      title: "BB vs All Open (3x)",
      startNodes: [
        "LJr080/HJf/COf/BUf/SBf",
        "HJr080/COf/BUf/SBf",
        "COr080/BUf/SBf",
        "BUr080/SBf",
        "SBr100"
      ],
      enabledForHero: ["BB"]
    },
    {
      title: "BB vs Limps",
      startNodes: [
        "LJc/HJf/COf/BUf/SBf",
        "HJc/COf/BUf/SBf",
        "COc/BUf/SBf",
        "BUc/SBf",
        "SBc"
      ],
      enabledForHero: ["BB"]
    },
    {
      title: "BB vs LJ Open",
      startNodes: [
        "LJr040/HJf/COf/BUf/SBf",
        "LJr060/HJf/COf/BUf/SBf",
        "LJr080/HJf/COf/BUf/SBf"
      ],
      enabledForHero: ["BB"]
    },
    {
      title: "BB vs HJ Open",
      startNodes: [
        "HJr040/COf/BUf/SBf",
        "HJr050/COf/BUf/SBf",
        "HJr060/COf/BUf/SBf",
        "HJr080/COf/BUf/SBf"
      ],
      enabledForHero: ["BB"]
    },
    {
      title: "BB vs CO Open",
      startNodes: [
        "COr040/BUf/SBf",
        "COr050/BUf/SBf",
        "COr060/BUf/SBf",
        "COr070/BUf/SBf",
        "COr080/BUf/SBf"
      ],
      enabledForHero: ["BB"]
    },
    {
      title: "BB vs BU Open",
      startNodes: [
        "BUr040/SBf",
        "BUr050/SBf",
        "BUr060/SBf",
        "BUr070/SBf",
        "BUr080/SBf"
      ],
      enabledForHero: ["BB"]
    },
    {
      title: "BB vs SB Open",
      startNodes: [
        "SBc",
        "SBr050",
        "SBr075",
        "SBr100",
        "SBr125",
        "SBr150",
        "SBr175",
        "SBr200"
      ],
      enabledForHero: ["BB"]
    },
    {
      title: "SB vs All Open (Common Sizes)",
      startNodes: [
        "LJr040/HJf/COf/BUf",
        "HJr040/COf/BUf",
        "COr050/BUf",
        "BUr060"
      ],
      enabledForHero: ["SB"]
    },
    {
      title: "SB vs All Open (3x)",
      startNodes: [
        "LJr080/HJf/COf/BUf",
        "HJr080/COf/BUf",
        "COr080/BUf",
        "BUr080"
      ],
      enabledForHero: ["SB"]
    },
    {
      title: "SB vs Limps",
      startNodes: [
        "LJc/HJf/COf/BUf",
        "HJc/COf/BUf",
        "COc/BUf",
        "BUc"
      ],
      enabledForHero: ["SB"]
    },
    {
      title: "SB vs LJ Open",
      startNodes: [
        "LJr040/HJf/COf/BUf",
        "LJr060/HJf/COf/BUf",
        "LJr080/HJf/COf/BUf"
      ],
      enabledForHero: ["SB"]
    },
    {
      title: "SB vs HJ Open",
      startNodes: [
        "HJr040/COf/BUf",
        "HJr050/COf/BUf",
        "HJr060/COf/BUf",
        "HJr080/COf/BUf"
      ],
      enabledForHero: ["SB"]
    },
    {
      title: "SB vs CO Open",
      startNodes: [
        "COr040/BUf",
        "COr050/BUf",
        "COr060/BUf",
        "COr070/BUf",
        "COr080/BUf"
      ],
      enabledForHero: ["SB"]
    },
    {
      title: "SB vs BU Open",
      startNodes: [
        "BUr040",
        "BUr050",
        "BUr060",
        "BUr070",
        "BUr080"
      ],
      enabledForHero: ["SB"]
    },
    {
      title: "BU vs All Open (Common Sizes)",
      startNodes: [
        "LJr040/HJf/COf",
        "HJr040/COf",
        "COr050"
      ],
      enabledForHero: ["BU"]
    },
{
      title: "BU vs Limps",
      startNodes: [
        "LJc/HJf/COf",
        "HJc/COf",
        "COc"
      ],
      enabledForHero: ["BU"]
    },
    {
      title: "BU vs All Open (3x)",
      startNodes: [
        "LJr080/HJf/COf",
        "HJr080/COf",
        "COr080"
      ],
      enabledForHero: ["BU"]
    },
    {
      title: "BU vs LJ Open",
      startNodes: [
        "LJr040/HJf/COf",
        "LJr060/HJf/COf",
        "LJr080/HJf/COf"
      ],
      enabledForHero: ["BU"]
    },
    {
      title: "BU vs HJ Open",
      startNodes: [
        "HJr040/COf",
        "HJr050/COf",
        "HJr060/COf",
        "HJr080/COf"
      ],
      enabledForHero: ["BU"]
    },
    {
      title: "BU vs CO Open",
      startNodes: [
        "COr040",
        "COr050",
        "COr060",
        "COr070",
        "COr080"
      ],
      enabledForHero: ["BU"]
    },
    {
      title: "BB vs SB limp",
      startNodes: [
        "SBc"
      ],
      enabledForHero: ["BB"]
    },
    {
      title: "BU vs SB 3B",
      startNodes: [
        "BUr060/SBr150/BBf"
      ],
      enabledForHero: ["BU"]
    },
    {
      title: "BU vs BB 3B",
      startNodes: [
        "BUr060/SBf/BBr200"
      ],
      enabledForHero: ["BU"]
    },
    {
      title: "BU vs All 3B",
      startNodes: [
        "BUr060/SBf/BBr200",
        "BUr060/SBr150/BBf"
      ],
      enabledForHero: ["BU"]
    },
    {
      title: "CO vs BU 3B",
      startNodes: [
        "COr050/BUr080/SBf/BBf"
      ],
      enabledForHero: ["CO"]
    },
    {
      title: "CO vs SB 3B",
      startNodes: [
        "COr050/BUf/SBr150/BBf"
      ],
      enabledForHero: ["CO"]
    },
    {
      title: "CO vs BB 3B",
      startNodes: [
        "COr050/BUf/SBf/BBr225"
      ],
      enabledForHero: ["CO"]
    },
    {
      title: "CO vs All 3B",
      startNodes: [
        "COr050/BUf/SBf/BBr225",
        "COr050/BUf/SBr150/BBf",
        "COr050/BUr080/SBf/BBf"
      ],
      enabledForHero: ["CO"]
    }
  ],
  "100_HU": [
    {
      title: "BB v SB 2BB",
      startNodes: [
        "SBr050"
      ],
      enabledForHero: ["BB"]
    },
    {
      title: "BB v SB 2.5BB",
      startNodes: [
        "SBr075"
      ],
      enabledForHero: ["BB"]
    },
    {
      title: "BB v SB 3BB",
      startNodes: [
        "SBr100"
      ],
      enabledForHero: ["BB"]
    },
    {
      title: "SB v BB 3B (Various Opens)",
      startNodes: [
        "SBr050/BBr150",
        "SBr050/BBr175",
        "SBr050/BBr200",
        "SBr050/BBr225",
        "SBr050/BBr250",
        "SBr055/BBr150",
        "SBr055/BBr175",
        "SBr055/BBr200",
        "SBr055/BBr225",
        "SBr055/BBr250",
        "SBr060/BBr125",
        "SBr060/BBr150",
        "SBr060/BBr175",
        "SBr060/BBr200",
        "SBr060/BBr225",
        "SBr060/BBr250",
        "SBr065/BBr125",
        "SBr065/BBr150",
        "SBr065/BBr175",
        "SBr065/BBr200",
        "SBr065/BBr225",
        "SBr065/BBr250",
        "SBr070/BBr125",
        "SBr070/BBr150",
        "SBr070/BBr175",
        "SBr070/BBr200",
        "SBr070/BBr225",
        "SBr070/BBr250",
        "SBr075/BBr125",
        "SBr075/BBr150",
        "SBr075/BBr175",
        "SBr075/BBr200",
        "SBr075/BBr225",
        "SBr075/BBr250"
      ],
      enabledForHero: ["SB"]
    },
    {
      title: "SB v BB 3B (2x Open)",
      startNodes: [
        "SBr050/BBr150",
        "SBr050/BBr175",
        "SBr050/BBr200",
        "SBr050/BBr225",
        "SBr050/BBr250"
      ],
      enabledForHero: ["SB"]
    },
    {
      title: "SB v BB 3B (2.1x Open)",
      startNodes: [
        "SBr055/BBr150",
        "SBr055/BBr175",
        "SBr055/BBr200",
        "SBr055/BBr225",
        "SBr055/BBr250"
      ],
      enabledForHero: ["SB"]
    },
    {
      title: "SB v BB 3B (2.2x Open)",
      startNodes: [
        "SBr060/BBr125",
        "SBr060/BBr150",
        "SBr060/BBr175",
        "SBr060/BBr200",
        "SBr060/BBr225",
        "SBr060/BBr250"
      ],
      enabledForHero: ["SB"]
    },
    {
      title: "SB v BB 3B (2.3x Open)",
      startNodes: [
        "SBr065/BBr125",
        "SBr065/BBr150",
        "SBr065/BBr175",
        "SBr065/BBr200",
        "SBr065/BBr225",
        "SBr065/BBr250"
      ],
      enabledForHero: ["SB"]
    },
    {
      title: "SB v BB 3B (2.4x Open)",
      startNodes: [
        "SBr070/BBr125",
        "SBr070/BBr150",
        "SBr070/BBr175",
        "SBr070/BBr200",
        "SBr070/BBr225",
        "SBr070/BBr250"
      ],
      enabledForHero: ["SB"]
    },
    {
      title: "SB v BB 3B (2.5x Open)",
      startNodes: [
        "SBr075/BBr125",
        "SBr075/BBr150",
        "SBr075/BBr175",
        "SBr075/BBr200",
        "SBr075/BBr225",
        "SBr075/BBr250"
      ],
      enabledForHero: ["SB"]
    }

  ]
}

export default scenarios
