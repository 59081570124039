import React from "react"
import {
  makeStyles,
  Button,
  InputLabel,
} from '@material-ui/core';
import Action from "./Action";
import {
  actionIdToParts,
  FOLD,
} from "../util/gameTree";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: 120,
    width: "100%"
  },
  select: {
    width: "100%"
  },
  actionsButton: {
    marginRight: 3,
    marginTop: 3,
    marginBottom: 3,
    paddingLeft: 15,
    paddingRight: 15,
    justifyContent: "left",
  }
}));

const optionToAction = (option, actions, leftToAct, actorIndex) => option.length === 1
  ? actions.find(action => action.id === option[0])
  : {
      name: `Fold to ${leftToAct[actorIndex+1]}`,
      color: actions.find(i => actionIdToParts(i.id).action === FOLD)?.color || "white",
      size: 0
    }

const noop = () => {};

export const NextOptions = ({nextOptions = [], actions = [], onChange=noop, disabled, leftToAct, onMouseEnter=noop, onMouseLeave=noop, label, fullWidth=true, size="small", buttonStyles={}}) => {
  const classes = useStyles();
  return (<>
    {label && <InputLabel id={`action-node-label`}>{label}</InputLabel>}
    {
      nextOptions
        .map((option) => {
          const lastAction = option.slice(-1)[0];
          const actorIndex = leftToAct.findIndex(i => i === actionIdToParts(lastAction).position);
          const actionData = optionToAction(option, actions, leftToAct, actorIndex);
          return <Button onMouseEnter={() => onMouseEnter(lastAction)} onMouseLeave={() => onMouseLeave(lastAction)} key={option.join(",")} onClick={() => onChange(option)} disabled={disabled} fullWidth={fullWidth} className={classes.actionsButton} size={size} variant="outlined" style={buttonStyles}>
              <Action
                name={actionData.name}
                color={actionData.color}
                size={actionData.size}
              />
            </Button>
        })
      }
    </>
  )
}

export default NextOptions;
