import React, { useEffect, useRef } from "react";
import { Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AuthContext from "../providers/AuthContext";

const ErrorFallback = ({error, resetErrorBoundary}) => {
  const { sessionExpired } = React.useContext(AuthContext);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!sessionExpired && !firstUpdate.current) resetErrorBoundary();
  }, [sessionExpired, resetErrorBoundary]);

  if (sessionExpired) {
    return <Typography>The page will be displayed once your session is refreshed</Typography>
  }

  return (
    <Alert severity="error" action={<Button onClick={resetErrorBoundary}>Reload</Button>}>
      {error.message} Please clear your cache and reload. If the issue persists let us know.
    </Alert>
  )
}

export default ErrorFallback;
