export const CONFIG_VALUE_ENABLED = "yes";
export const CONFIG_VALUE_DISABLED = "no";
export const SAMPLE_MODE_HINT_NONE = "none";
export const SAMPLE_MODE_HINT_ACTION_HOVER = "action_hover";
export const ANSWER_MODE = "answerMode";
export const RNG_SCORING = "rngScoring";
export const SHOW_VILLAIN_CARDS = "showVillainCards";
export const AUTO_NEXT = "autoNext";
export const HERO_RANGE = "heroRange";
export const VILLAIN_RANGES = "villainRanges";

export const getConfigDefault = (key, fallback) => {
  try {
    return localStorage.getItem(key) || fallback
  } catch (err) {
    console.warn(err);
    return fallback;
  }
}

export const saveConfig = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (err) {
    console.warn(`Unable to store ${key}`);
  }
}

export const CONFIG_KEYS = {
  experimentalFeatures: "experimentalFeatures",
  palette: "palette",
  frequencyMode: "frequencyMode",
  optionsMode: "optionsMode",
  rangeStringFormat: "rangeStringFormat",
  sampleModeHinting: "sampleModeHinting",
  weightedMode: "weightedMode",
  normalizeWeightedMode: "normalizeWeightedMode",
  normalizeRangeString: "normalizeRangeString",
  answerMode: ANSWER_MODE,
  rngScoring: RNG_SCORING,
  showVillainCards: SHOW_VILLAIN_CARDS,
  autoNext: AUTO_NEXT,
  heroRange: HERO_RANGE,
  villainRanges: VILLAIN_RANGES,
}
