import React, { useEffect, useState } from "react"
import {
  makeStyles,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  Switch,
  TextField,
  Snackbar,
  Checkbox,
} from "@material-ui/core";
import {
  Alert
} from "@material-ui/lab";
import Action from "../components/Action";
import {
  isRaise
} from "../util/gameTree";
import { frequenciesToRangeString, RANGE_STRING_PRO_POKER_TOOLS } from "../util/range";
import { CONFIG_VALUE_ENABLED, CONFIG_VALUE_DISABLED, CONFIG_KEYS } from "../util/config";
import { useConfig } from "../hooks";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: 120,
    width: "100%"
  },
  select: {
    width: "100%"
  },
  dialogBody: {
    padding: 20,
    paddingTop: 0
  },
  rangeCheckboxLabel: {
    width: "100%"
  },
}));

export const CopyRangeDialog = ({ open, onClose, frequencyData, actions = [], format }) => {
  const classes = useStyles();
  const [actionsInRange, setActionsInRange] = useState(actions.map(i => i.id).filter(i => !i.endsWith("f") && !i.endsWith("c")));
  const [snackbarContent, setSnackbarContent] = useState(undefined);
  const [textContent, setTextContent] = useState(undefined);
  const [config, handleConfigChange] = useConfig();
  const normalizeRangeString = config.normalizeRangeString === CONFIG_VALUE_ENABLED;

  useEffect(() => {
    if (frequencyData && actionsInRange) {
      setTextContent(frequenciesToRangeString(frequencyData, actionsInRange, format, normalizeRangeString));
    }
  }, [frequencyData, actionsInRange, format, normalizeRangeString])

  const toggleAction = (id) => {
    actionsInRange.indexOf(id) !== -1
      ? setActionsInRange(actionsInRange.filter(i => i !== id))
      : setActionsInRange(existing => existing.concat([id]))
  }

  const handleShortcutToggle = (ids) => {
    const areAllSelected = ids.every(i => actionsInRange.indexOf(i) !== -1);
    areAllSelected
      ? setActionsInRange(actionsInRange.filter(i => ids.indexOf(i) === -1))
      : setActionsInRange(existing => [...(new Set(existing.concat(ids)))])
  }

  const handleError = (err) => {
    console.error(err);
    setSnackbarContent({ type: "error", text: "Error copying range" });
  }

  const handleCopy = () => {
    try {
      navigator
        .clipboard
        .writeText(frequenciesToRangeString(frequencyData, actionsInRange, format, normalizeRangeString))
        .then(() => setSnackbarContent({ type: "success", text: "Range Copied!" }))
        .catch(handleError)
        ;
    } catch (err) {
      handleError(err);
    }
  }

  const handleFocus = (event) => event.target.select && event.target.select();

  const allRaises = actions.filter(action => isRaise(action.id, true));

  return (<div>
    <Snackbar open={snackbarContent !== undefined} autoHideDuration={6000} onClose={() => setSnackbarContent(undefined)}>
      {snackbarContent && <Alert onClose={() => setSnackbarContent(undefined)} severity={snackbarContent.type}>
        {snackbarContent.text}
      </Alert>}
    </Snackbar>
    <Dialog maxWidth="xs" fullWidth={true} onClose={onClose} aria-labelledby="dialog-title" open={open}>
      <DialogTitle id="dialog-title">Copy Range String</DialogTitle>
      <div className={classes.dialogBody}>
        <Box mb={2}>
          <Typography variant="subtitle1">Select which actions to include in your range</Typography>
          {
            actions.map((action, i) => (
              <Box key={i}>
                <FormControlLabel
                  control={<Checkbox onChange={() => toggleAction(action.id)} checked={actionsInRange.indexOf(action.id) !== -1} fullWidth={true} />}
                  label={<Action name={action.name} color={action.color} size={action.size} />}
                  classes={{ root: classes.rangeCheckboxLabel, label: classes.rangeCheckboxLabel }}
                />
              </Box>
            ))
          }
          {
            allRaises.length > 1 && <FormControlLabel
              control={<Checkbox onChange={() => handleShortcutToggle(allRaises.map(i => i.id))} checked={allRaises.every(i => actionsInRange.indexOf(i.id) !== -1)} />}
              label="All Raises"
              classes={{ root: classes.rangeCheckboxLabel, label: classes.rangeCheckboxLabel }}
            />
          }
        </Box>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={<Switch onChange={e => handleConfigChange(CONFIG_KEYS.normalizeRangeString, e.target.checked ? CONFIG_VALUE_ENABLED : CONFIG_VALUE_DISABLED)} checked={normalizeRangeString} />}
            label="Normalize Range Strings"
          />
        </FormControl>
        <Button onClick={handleCopy} fullWidth={true} color="primary" variant="outlined">Copy</Button>
        {
          format === RANGE_STRING_PRO_POKER_TOOLS && <FormHelperText error={true}>
            Due to limitations with the ProPokerTools format, the range string weights
            are rounded up
          </FormHelperText>
        }
        <Box mt={2}>
          <TextField
            multiline
            rowsMax={4}
            fullWidth={true}
            value={textContent}
            onClick={handleFocus}
            label="Manual Copy"
            variant="filled"
          />
        </Box>
      </div>
    </Dialog>
  </div>
  )
}

export default CopyRangeDialog;
