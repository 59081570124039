import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    background: "white",
    borderRadius: "50%",
    border: "1px solid #e8e8e8",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    fontWeight: "900",
    boxShadow: "0px 1px #c0c0c0",
    color: "black"
  }
}));

export const DealerButton = ({size, style={}}) => {
  const classes = useStyles();
  return (<div style={{width: size, height: size, ...style}} className={classes.button}>
    D
  </div>)
}

export default DealerButton;
