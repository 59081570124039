import * as localForage from 'localforage';
import { nanoid } from "nanoid";
import Observable from "zen-observable";
const lfo = require('localforage-observable');
const localforage = lfo.extendPrototype(localForage);
localforage.newObservable.factory = (subscribeFn) => new Observable(subscribeFn);

const KEY = "rangeGroups";

const groupObservable = localforage
  .ready()
  .then(() => localforage.configObservables({
    crossTabNotification: true,
  }))
  .then(() => localforage.newObservable({
    key: KEY,
    crossTabNotification: true,
  }))

export const getRangeGroups = () => localforage.getItem(KEY).then(val => val || []);

export const storeRangeGroup = async ({name, ranges, id}) => {
  await localforage.ready();
  const rangeGroups = await getRangeGroups();
  if (!id) {
    id = nanoid();
  }
  const group = {name, ranges, id};
  const idx = rangeGroups.findIndex(i => i.id === id);
  rangeGroups[idx === -1 ? rangeGroups.length : idx] = group;
  await localforage.setItem(KEY, rangeGroups);
  return group;
}

export const addRangeToGroup = async (gameTreePath, position, rangeSet, version, name, groupId) => {
  await localforage.ready();
  const rangeGroups = await getRangeGroups();
  const groupIdx = rangeGroups.findIndex(i => i.id === groupId);
  if (groupIdx === -1) throw Error("Unable to find group");
  const group = rangeGroups[groupIdx];
  group.ranges = group.ranges.concat([{id: nanoid(), gameTreePath, position, name, version, rangeSet}]);
  rangeGroups[groupIdx] = group;
  await localforage.setItem(KEY, rangeGroups);
  return group;
}

export const deleteRangeGroup = async (id) => {
  await localforage.ready();
  const rangeGroups = await getRangeGroups();
  await localforage.setItem(KEY, rangeGroups.filter(i => i.id !== id));
  return rangeGroups;
}

export const subscribe = async (fn) => {
  await localforage.ready();
  const observable = await groupObservable
  return observable.subscribe({
    next: function(d) {
      if (d.key) fn(d)
    }
  });
}
