import React, { useState, useEffect, createContext, useCallback } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from '@material-ui/lab/Alert';
import { AUTH_TOKEN, attemptTokenRefresh, isExpired, redirectToLogin, storeTokens } from "../util/auth";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [sessionExpired, setSessionExpired] = useState(false);

  const handleTokens = (accessToken, refreshToken) => {
    storeTokens(accessToken, refreshToken);
    setToken(accessToken);
    setLoading(false);
    window.history.replaceState(null, null, window.location.pathname);
  }

  const handleError = (errorMessage) => {
    console.error(errorMessage);
    setError(errorMessage);
    setLoading(false);
  }

  const checkLoginStatus = useCallback(() => {
    try {
      if (!token) {
        // If not set or it's expired, check if we've come back from a redirect
        const qsParams = new URLSearchParams(window.location.search);
        const accessToken = qsParams.get("access_token");
        const refreshToken = qsParams.get("refresh_token");
        const err = qsParams.get("error");
        if (err) {
          handleError(`${err}: ${qsParams.get("error_description")}`);
          setLoading(false);
        } else if (accessToken) {
          // It's a login redirect so handle it
          handleTokens(accessToken, refreshToken);
          setLoading(false);
        } else {
          // No valid token and nothing in url so redirect to login
          redirectToLogin();
        }
      } else if (isExpired(token, 180)) {
        console.debug("Attmepting to refresh token...");
        // Try and silently refresh the access token using a refresh token
        attemptTokenRefresh()
          .then(resp => handleTokens(resp.access_token, resp.refresh_token))
          .catch(() => {
            console.warn("Unable to refresh token");
            setSessionExpired(true)
          })
          .finally(() => setLoading(false));
      }
    } catch (err) {
      handleError(err.message);
      setLoading(false);
    }
  }, [token]);

  const handleExternalTokenRefresh = (e) => {
    if (e.key === AUTH_TOKEN && !isExpired(e.newValue)) {
      setToken(e.newValue);
      setSessionExpired(false);
    }
  }

  useEffect(() => {
    checkLoginStatus();
    const interval = setInterval(()=>{
      checkLoginStatus()
    }, 3 * 60 * 1000) // Check login status every 3 mins

    window.addEventListener('storage', handleExternalTokenRefresh);

    return () => {
      clearInterval(interval)
      window.removeEventListener('storage', handleExternalTokenRefresh);
    }
  }, [token, checkLoginStatus]);

  if (loading) return <div style={{padding: 15, textAlign: "center"}}>
    <CircularProgress/>
  </div>

  if (!loading && (error || !token)) return <Alert severity="error">We were unable to log you in</Alert>;

  return (
    <AuthContext.Provider value={{ token, sessionExpired, setSessionExpired }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
