import { memo } from "react";
import {
  makeStyles,
  Chip,
  Typography,
  Box,
} from '@material-ui/core';
import { MonetizationOn } from '@material-ui/icons';
import logo from "../assets/logo.png"
import DealerButton from "./DealerButton";
import Card from "./Card";
import { rotateArray, toFixedIfRequired } from "../util";
import { actorLastAction, actionIdToString, isFold } from "../util/gameTree";

const TABLE_HEIGHT = 355;
const TABLE_WIDTH = 555;
const TABLE_WIDTH_COMPACT = 275;
const TABLE_HEIGHT_COMPACT = 375;
const CARD_SIZE = 45;
const CARD_SIZE_COMPACT = 40;
const BUTTON_SIZE = 25;

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  table: props => ({
    position: "relative",
    margin: 24,
    marginTop: props.cardSize + props.labelHeight,
    marginBottom: props.cardSize + props.labelHeight * 2.5,
    height: TABLE_HEIGHT,
    width: TABLE_WIDTH,
    borderRadius: "50%",
    background: `radial-gradient(#0aa045, #014618)`,
    [theme.breakpoints.down(props.compactBreakpoint)]: {
      height: TABLE_HEIGHT_COMPACT,
      width: TABLE_WIDTH_COMPACT,
    },
    // transform: "perspective(400px) rotateX(12deg)",
    transformStyle: "preserve-3d",
    '&:before': {
      content: '""',
      display: "block",
      top: 0,
      bottom: 1,
      left: 1,
      right: 1,
      fontSize: 11,
      position: "absolute",
      borderRadius: "50%",
      zIndex: 1,
      backgroundColor: "transparent",
      boxShadow: "inset 0 0 20px #000, inset 0 0 20px #fff, inset 0 0 20px #B0B0B0, inset 0 0 0 21px #252525,  -3px 10px 0px #3a3a3a,  3px 10px 0px #3a3a3a",
      border: "1px solid #636363",
    }
  }),
  feltCenter: {
    position: "absolute",
    left: "50%",
    top: "50%",
    zIndex: 1,
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    width: "100%"
  },
  feltLogo: {
    display: "block",
    width: "50%",
    margin: "auto",
    opacity: 0.5
  },
  pot: {
    color: "white",
    display: "inline-block",
    padding: 5,
    background: "#00000024",
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: "5px",
    fontSize: "12px"
  },
  chip: {
    height: 20
  },
  seat: props => ({
    position: "absolute",
    zIndex: 3,
    // transform: "perspective(400px) rotateX(-12deg) translateZ(12px) translateY(-24px)",
    transformStyle: "preserve-3d",
    overflow: "hidden",
    textAlign: "center",
  }),
  seatLabel: props => ({
    height: props.labelHeight
  }),
  cards: props => ({
    position: "relative",
    display: "flex",
    flexDirection: "row",
    zIndex: 2,
    width: props.cardSize * 2,
    transition: "opacity 0.2s linear, transform 0.3s linear",
    opacity: 1,
    minHeight: props.cardSize * 1.333
  }),
  folded: props => ({
    opacity: 0,
    transition: "opacity 0.5s linear, transform 0.65s linear",
    transform: "translateY(100%)"
  }),
  button: {
    position: "absolute",
    zIndex: 1
  },
  label: {
    position: "relative",
    textTransform: "uppercase",
    borderRadius: 2,
    width: "100%",
    border: "0.5px solid #e8e8e8",
    textAlign: "center",
    background: "#444444",
    color: "white",
    fontWeight: 700,
    fontSize: 11,
    zIndex: 3
  },
  c: {
    background: "#1bc71b",
    color: "white"
  },
  f: {
    background: "#787878",
    color: "white"
  },
  r: {
    background: "#ff8d00",
    color: "white"
  },
  m: {
    background: "#ff8d00",
    color: "white"
  },
  j: {
    background: "red",
    color: "white"
  }
}));

export const PokerTable = memo(({ compact, seats, atBottom, dealerIndex, potDetails, gameTreePath, hideFolded=true, compactBreakpoint="xs", labelHeight = 20 }) => {
  const cardSize = compact ? CARD_SIZE_COMPACT : CARD_SIZE;
  const classes = useStyles({cardSize, labelHeight, compactBreakpoint});

  const xradius = (compact ? TABLE_WIDTH_COMPACT : TABLE_WIDTH) / 2;
  const yradius = (compact ? TABLE_HEIGHT_COMPACT : TABLE_HEIGHT) / 2;
  const buttonRadius = BUTTON_SIZE / 2;
  const startRotation = 90;
  const buttonRotation = ((360 / (seats.length || 1)) * dealerIndex + 1) + startRotation + 5
  const buttonLeft = (Math.cos(buttonRotation * Math.PI / 180) * (xradius - cardSize - 30) - buttonRadius) + xradius;
  const buttonTop = (Math.sin(buttonRotation * Math.PI / 180) * (yradius - cardSize - 30) - buttonRadius) + yradius;

  // Rotate the seats so that hero is always at the bottom
  const orderedSeats = (atBottom ? rotateArray(seats, seats.findIndex(i => i.position === atBottom)) : seats)
    .map(seat => {
      const lastAction = actorLastAction(gameTreePath, seat.position);
      return {
        ...seat,
        action: lastAction || undefined,
        playerTotal: potDetails.playerTotals[seat.position] || 0
      }
    });
  return (<div className={classes.tableContainer}>
    <Box boxShadow={22} className={`${classes.table}`}>
      <div className={classes.feltCenter}>
        <img src={logo} className={classes.feltLogo} alt="felt logo"/>
        {potDetails && <Box mt={1} className={classes.pot}>
          <Typography>Pot: {potDetails?.pot.toFixed(2) || 0}BB</Typography>
        </Box>}
      </div>
      {
        <div style={{
          top: buttonTop,
          left: buttonLeft
        }} className={classes.button}>
          <DealerButton size={BUTTON_SIZE} />
        </div>
      }
      {
        orderedSeats.map((seat, i) => {
          const rotation = ((360 / seats.length) * i + 1) + startRotation
          const left = (Math.cos(rotation * Math.PI / 180) * (xradius + cardSize / 2) - cardSize) + xradius;
          const top = (Math.sin(rotation * Math.PI / 180) * (yradius + cardSize / 2) - cardSize) + yradius;
          return <div key={i} className={classes.seat} style={{
            top,
            left
          }}>
            <Seat {...seat} hideFolded={hideFolded} cardSize={cardSize} labelHeight={labelHeight}/>
          </div>
        })
      }
    </Box>
  </div>)
})

export const Seat = memo(({ position, action, sittingOut, playerTotal=0, cards = [], hideFolded, faceDown, cardSize, labelHeight }) => {
  const classes = useStyles({cardSize, labelHeight});
  return <>
    <div className={`${classes.cards} ${action && hideFolded && isFold(action) ? classes.folded : ""}`}>
      {cards.map((card, i) => (
        <Card
          key={i}
          rank={!faceDown ? card.rank : undefined}
          suit={!faceDown ? card.suit : undefined}
          width={cardSize}
        />
      ))}
    </div>
    <div className={classes.label}>{position}</div>
    {action && <div className={`${classes[action[2]]} ${classes.label}`}>{actionIdToString(action, true)}</div>}
    {sittingOut && <div className={`${classes.f} ${classes.label}`}>Sit Out</div>}
    {playerTotal > 0 && <Chip className={classes.chip} size="small" icon={<MonetizationOn/>} label={`${toFixedIfRequired(playerTotal)}BB`}/>}
  </>
})

export default PokerTable;