import React from "react"
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Action from "./Action";
import {
  evToBB
} from "../util";

const useStyles = makeStyles((theme) => ({
  th: {
    fontWeight: "bold"
  }
}));

export const StrategyTable = ({actions, percentages, evData, size="medium"}) => {
  const classes = useStyles();

  return (<TableContainer>
    <Table aria-label="frequency table" size={size}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.th}>Action</TableCell>
          <TableCell className={classes.th} align="right">Freq</TableCell>
          <TableCell className={classes.th} align="right">EV</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(actions || []).map((action, i) => (
          <TableRow key={i}>
            <TableCell component="th" scope="row">
              <Action key={i} name={action.name} color={action.color} size={action.size}/>
            </TableCell>
            <TableCell align="right">{Number.isNaN(percentages[action.id]) ? "Not in Range" : (percentages[action.id] || 0).toFixed(2) + "%"}</TableCell>
            <TableCell align="right">{evToBB(evData[action.id] || 0).toFixed(2)}BB</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>)
}

export default StrategyTable;
