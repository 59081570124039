import APIClient from "../api";
const DOMAIN = 'zenith.poker';
const { REACT_APP_AUTH_URL: REDIRECT_URI = `${window.location.protocol}//${window.location.host}/auth` } = process.env;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const AUTH_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";


export const getToken = (token=AUTH_TOKEN) => localStorage.getItem(token);
export const storeTokens = (accessToken, refreshToken) => {
  localStorage.setItem(AUTH_TOKEN, accessToken);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};
export const removeToken = (token=AUTH_TOKEN) => localStorage.removeItem(token);

export const getExpirationDate = (token) => {
  if (!token) return null;
  const jwt = JSON.parse(atob(token.split('.')[1]));
  return jwt?.exp ? jwt.exp * 1000 : null;
};
export const isExpired = (token, withinSecs=0) => {
  if (!token) return true;
  const exp = getExpirationDate(token);
  if (!exp) return false;
  return Date.now() + (withinSecs * 1000) > exp;
};
export const attemptTokenRefresh = () => {
  const refreshToken = getToken(REFRESH_TOKEN)
  if (!refreshToken) return Promise.reject("Token refresh failed");
  return APIClient.refreshAuthToken(refreshToken)
    .catch(err => {
      console.warn(`Token refresh failed: ${err.message}`);
      removeToken(REFRESH_TOKEN)
      throw err;
    });
}

export const redirectToLogin = () => {
  window.location = getLoginUrl()
}

export const getLoginUrl = () => {
  return `https://${DOMAIN}/oauth/authorize/?response_type=code&redirect_uri=${REDIRECT_URI}/token&client_id=${CLIENT_ID}`
}

export class ExpiredTokenError extends Error {}
export class APIError extends Error {}
