import { makeStyles } from '@material-ui/core';
import logo from "../assets/icon.png"

const useStyles = makeStyles((theme) => ({
  card: {
    border: "1px solid #e8e8e8",
    borderRadius: 5,
    padding: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
    fontWeight: 800,
    textShadow: "2px 2px #585858b3",
    fontFamily: "Open Sans",
    justifyContent: "center"
  },
  back: {
    background: "#000"
  },
  c: {
    background: "#018801"
  },
  s: {
    background: "#444444"
  },
  d: {
    background: "#0066d8"
  },
  h: {
    background: "#dc4e3d"
  },
}));

export const Card = ({rank, suit, width=50, style={}}) => {
  const classes = useStyles();
  const getClass = (s) => {
    switch (s) {
      case "d": return classes.d
      case "s": return classes.s
      case "c": return classes.c
      case "h": return classes.h
      default: return classes.back;
    }
  }
  return (<div style={{fontSize: width-4, width: width, height: width*1.333}} className={`${classes.card} ${getClass(suit)}`}>
    {rank ? <span>{rank}</span> : <img alt="back of playing card" width="50%" src={logo}/>}
  </div>)
}

export default Card
