import React, { createContext, useState, useEffect } from "react"
import { getConfigDefault, CONFIG_KEYS, saveConfig, CONFIG_VALUE_DISABLED, CONFIG_VALUE_ENABLED, SAMPLE_MODE_HINT_NONE } from "../util/config";
import { RANGE_STRING_GTOPLUS } from "../util/range";

export const ConfigContext = createContext(null)

export function ConfigProvider({ children }) {
  const [config, setConfig] = useState({
    experimentalFeatures: getConfigDefault(CONFIG_KEYS.experimentalFeatures, CONFIG_VALUE_DISABLED),
    palette: getConfigDefault(CONFIG_KEYS.palette, "Standard"),
    frequencyMode: getConfigDefault(CONFIG_KEYS.frequencyMode, CONFIG_VALUE_ENABLED),
    rangeStringFormat: getConfigDefault(CONFIG_KEYS.rangeStringFormat, RANGE_STRING_GTOPLUS),
    sampleModeHinting: getConfigDefault(CONFIG_KEYS.sampleModeHinting, SAMPLE_MODE_HINT_NONE),
    weightedMode: getConfigDefault(CONFIG_KEYS.weightedMode, CONFIG_VALUE_DISABLED),
    normalizeWeightedMode: getConfigDefault(CONFIG_KEYS.normalizeWeightedMode, CONFIG_VALUE_DISABLED),
    normalizeRangeString: getConfigDefault(CONFIG_KEYS.normalizeRangeString, CONFIG_VALUE_ENABLED),
    answerMode: getConfigDefault(CONFIG_KEYS.answerMode, CONFIG_VALUE_ENABLED),
    rngScoring: getConfigDefault(CONFIG_KEYS.rngScoring, CONFIG_VALUE_ENABLED),
    showVillainCards: getConfigDefault(CONFIG_KEYS.showVillainCards, CONFIG_VALUE_ENABLED),
    autoNext: getConfigDefault(CONFIG_KEYS.autoNext, CONFIG_VALUE_DISABLED),
    heroRange: getConfigDefault(CONFIG_KEYS.heroRange, '100'),
    villainRanges: getConfigDefault(CONFIG_KEYS.villainRanges, '100'),
  });

  useEffect(() => {
    Object
      .entries(config)
      .forEach(([k, v]) => saveConfig(k, v))
  }, [config]);

  const handleConfigChange = (key, value) => {
    setConfig(e => ({ ...e, [key]: value }));
  }

  return <ConfigContext.Provider value={[config, handleConfigChange]}>{children}</ConfigContext.Provider>
}
