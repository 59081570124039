import { useErrorHandler } from "react-error-boundary";
import {
  Box,
  Button
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import APIClient from "../api";

export const refreshPermissions = () => {
  return APIClient.resyncAccess()
    .then(() => {
      window.location.reload()
    });
}

export const BasicAccessAlert = () => {
  const handleError = useErrorHandler();

  const handleRefreshPermissions = () => refreshPermissions()
    .catch((err) => {
      console.error(err)
      handleError("Unable to refresh permissions")
    });

  return <Box mb={1}>
    <Alert
      severity="info"
      action={
        <Button color="inherit" size="small" onClick={handleRefreshPermissions}>
          Refresh Access
      </Button>
      }
    >
      Consider purchasing products from the shop which provide access to more ranges and positions. Get in touch on Discord to find out more.
  </Alert>
  </Box>
}

export const NoAccessAlert = () => {
  const handleError = useErrorHandler();

  const handleRefreshPermissions = () => refreshPermissions()
    .catch(handleError("Unable to refresh permissions"));

  return <Box mb={1}>
    <Alert
      severity="error"
      action={
        <Button color="inherit" size="small" onClick={handleRefreshPermissions}>
          Refresh Access
      </Button>
      }
    >
      You do not currently have access to any Ranges. Please get in touch on the Discord channel to gain access.
  </Alert>
  </Box>
}

const AccessAlerts = {
  BasicAccessAlert,
  NoAccessAlert
}

export default AccessAlerts;
