export const RANGE_STRING_GTOPLUS = "gtoplus";
export const RANGE_STRING_SIMPLE_PREFLOP = "simplepreflop";
export const RANGE_STRING_PRO_POKER_TOOLS = "ppt";
export const RANGE_STRING_MONKER = "monker";

const rankCodes = {
  '2': 0,
  '3': 1,
  '4': 2,
  '5': 3,
  '6': 4,
  '7': 5,
  '8': 6,
  '9': 7,
  'T': 8,
  'J': 9,
  'Q': 10,
  'K': 11,
  'A': 12
}

const suits = [ 'h', 'c', 'd', 's' ];
const ranks = Object.keys(rankCodes).reverse();
const axArray = ranks.slice(1);
const kxArray = axArray.slice(1);
const qxArray = kxArray.slice(1);
const jxArray = qxArray.slice(1);
const txArray = jxArray.slice(1);
const _9xArray = txArray.slice(1);
const _8xArray = _9xArray.slice(1);

// Create hash with keys as card ranks, and values as their codes
const toHash = (highRank, arr) => {
  return arr.reduce((acc, next, idx) => Object.assign(acc, { [highRank + next]: idx }), { _size: arr.length });
}

const ax = toHash('A', axArray)
const kx = toHash('K', kxArray)
const qx = toHash('Q', qxArray)
const jx = toHash('J', jxArray)
const tx = toHash('T', txArray)
const _9x = toHash('9', _9xArray)
const _8x = toHash('8', _8xArray)

// reduce the array down to groups of consecutive items in i.e. no nulls between them
const comboGroupReducer = (acc, next) => {
  const idx = acc.length - 1;
  next === null
    ? acc.push([])
    : acc[idx].push(next)
  return acc;
};

const processCombos = (hash, combos) => {
  // Given a hash e.g. Ax and combos e.g. [A2, A3, K2...], generate groups for combos which are in that hash
  return Object
    .keys(hash)
    // create an array of combos where combo at an index is the key from the given hash whose value is === index if the key is in
    // the given combos (otherwise null). e.g. for Ax, the value at index 0, will be A if AQ is in combos else null
    .reduce((acc, k) => {
      acc[hash[k]] = combos.has(k) ? k : null;
      return acc;
    }, new Array(hash._size))
    .reduce(comboGroupReducer, [[]])
    .filter(i => i.length > 0);
}

const groupifyPairs = (pairs) => {
  const containedCodes = new Array(13).fill(null);

  pairs.forEach(p => {
    const code = rankCodes[p[0]]
    containedCodes[code] = p
  });

  return containedCodes
    .reverse()
    .reduce(comboGroupReducer, [[]])
    .filter(i => i.length > 0);
}

const shortenPairs = (pairs) => {
  const toShortNotation = (group) => {
    const first = group[0]
    if (group.length === 1) return first
    const last = group[group.length - 1]
    return group[0] === 'AA' ? `${last}+` : `${first}-${last}`
  }

  const groups = groupifyPairs(pairs)
  return groups.map(toShortNotation)
}

const shortenNonPairs = (combos, suffix) => {
  const toShortNotation = suffix => (hash, group) => {
    const first = group[0]
    if (group.length === 1) return first
    const last = group[group.length - 1]
    return hash[first] === 0
      ? `${last}${suffix}+`
      : `${first}${suffix}-${last}${suffix}`;
  }
  // Detect connections in descending order
  // Only accept all groups that are at least 3 long
  const orderedHashes = [ax, kx, qx, jx, tx, _9x, _8x]
  let remainingCombos = new Set(combos)
  let results = orderedHashes
    .reduce((acc, hash) => {
      // Group combos according to ordered hashes e.g. group all Ax, Kx and ensure all groups have at least 3 combos
      const groups = processCombos(hash, remainingCombos).filter(i => i.length >= 3)
      // If a group is valid, remove it's combos from the remaining combos
      groups.flat().forEach(i => {
        remainingCombos.delete(i)
      });
      // Convert groups to short notation
      const notations = groups.map(x => toShortNotation(suffix)(hash, x))
      return acc.concat(notations)
    }, []);

  return results.concat(Array.from(remainingCombos).map(x => x + suffix))
}

const sortOut = (combos) => {
  const offsuit = new Set();
  const suited = new Set();
  const pairs = new Set();
  combos.forEach(([rank1, rank2, suit]) => {
    const combo = rank1 + rank2;
    if (rank1 === rank2) pairs.add(combo)
    else if (!suit || suit === "o") offsuit.add(combo)
    else if (suit === "s") suited.add(combo)
    else throw new Error(`Invalid suit ${suit} of ${combo}!`)
  });

  return { offsuit, suited, pairs }
}

const unsuitNonPairs = (os, su) => {
  const osArray = Array.from(os);
  const suArray = Array.from(su);
  const intersection = new Set();
  for (const combo of osArray) if (su.has(combo.replace(/o/g, 's'))) intersection.add(combo.replace(/o/g, ''));

  const intersectionFilter = suffix => i => !intersection.has(i.replace(new RegExp(suffix, "g"), ''));

  return Array.from(intersection)
    .concat(
      osArray.filter(intersectionFilter("o")),
      suArray.filter(intersectionFilter("s")),
    );
}

const shortenRange = (combos) => {
  const { offsuit, suited, pairs } = sortOut(combos);
  const ps = shortenPairs(pairs);
  const os = shortenNonPairs(offsuit, 'o');
  const su = shortenNonPairs(suited, 's');
  const nonpairs = unsuitNonPairs(new Set(os), new Set(su));

  return ps.concat(nonpairs).join(', ');
}

const getPptAbbreviation = (combo) => {
  var r = new RegExp(/([AKQJT1-9]+)(o|s)/g);
  const subs = combo.match(r);
  if (!subs) return combo;
  let transformed = `${combo}`;
  subs.forEach(sub => {
    let newVal;
    if (sub.endsWith("o")) {
      newVal = `${sub[0]}x${sub[1]}y`
    } else {
      newVal = `${sub[0]}x${sub[1]}x`
    }
    transformed = transformed.replace(sub, newVal);
  })
  return transformed;  
}

export const handsInCombo = (combo) => {
  const [rank1, rank2, suitedness] = combo.split("");
  let handler;
  if (rank1 === rank2) {
    // 6 possible pairs
    handler = getPocketPairHands;
  } else if (suitedness === "s") {
    handler = getSuitedHands;
  } else if (suitedness === "o") {
    handler = getOffsuitHands;
  }
  return [...handler(rank1, rank2, suitedness)];
}

const getPocketPairHands = (rank) => {
  const set = new Set();
  for (var s1 = 0; s1 < suits.length; s1++) {
    const suit1 = suits[s1]
    for (var s2 = s1 + 1; s2 < suits.length; s2++) {
      const suit2 = suits[s2]
      set.add(rank + suit1 + rank + suit2)
    }
  }
  return set;
}

const getSuitedHands = (rank1, rank2) => {
  const set = new Set();
  // 4 possible suited combinations
  for (var s = 0; s < suits.length; s++) {
    const suit = suits[s]
    set.add(rank1 + suit + rank2 + suit)
  }
  return set;
}

const getOffsuitHands = (rank1, rank2) => {
  const set = new Set();
  // 12 possible offsuit combinations
  for (var s1 = 0; s1 < suits.length; s1++) {
    const suit1 = suits[s1]
    for (var s2 = 0; s2 < suits.length; s2++) {
      if (s1 === s2) continue // ignore suited cards
      const suit2 = suits[s2]
      set.add(rank1 + suit1 + rank2 + suit2)
    }
  }
  return set;
}

const formatters = {
  [RANGE_STRING_GTOPLUS]: (rangeString, weight) => rangeString.split(",").map(i => `${i}:${(weight).toFixed(3)}`).join(","),
  [RANGE_STRING_SIMPLE_PREFLOP]: (rangeString, weight) => rangeString.split(",").map(i => `[${(weight * 100).toFixed(1)}]${i}[/${(weight * 100).toFixed(1)}]`).join(","),
  [RANGE_STRING_PRO_POKER_TOOLS]: (rangeString, weight) => rangeString.split(",").map(i => `${getPptAbbreviation(i)}@${Math.min(Math.ceil(weight * 100), 100)}`).join(","), // 10000 because ppt doesn't support decimals.
  [RANGE_STRING_MONKER]: (rangeString, weight) => rangeString.split(",").map(handsInCombo).flat().map(i => `${i}@${(Math.min(100, Math.ceil(weight * 100)))}`).join(","),
}

/**
 * Transform a frequency object into a range string
 * @param {*} frequencies The frequencies object where the keys are combos, and the values are objects which have actions as keys and freqs as values
 * @param [{}] includedActions The actions to include in the range string
 * @param string format The string format. Supported options are gtoplus and simplepreflop
 * @returns string The weighted Range string
 */
export const frequenciesToRangeString = (frequencies, includedActions, format, normalizeRangeString = true) => {
  // Transform the frequencies into an object where the keys are weights, and all the
  // values are the combos with that weight e.g. {0.5: ['AKs","AKo"], 1: ["AA", "KK"]}
  // Necessary to get in this structure to be able to "shorten" the combo strings
  const weightedCombosMap = Object.entries(frequencies).reduce((acc, [combo, frequencyData]) => {
    const relevantActionsWeight = Object
      .entries(frequencyData)
      .filter(([key, value]) => includedActions.indexOf(key) !== -1 && !Number.isNaN(value))
      .reduce((total, [key, value]) => total + value, 0)
      .toFixed(3)
    return {
      ...acc,
      [relevantActionsWeight]: (acc[relevantActionsWeight] || []).concat([combo])
    }
  }, {});

  const getWeightedRangeString = formatters[format];

  // Get the highest frequency for the included actions to normalize
  // const includedFrequenciesTotal = Object.keys(weightedCombosMap).map(parseFloat).reduce((a, b) => a + b, 0);
  const highestIncludedActionFrequency = Math.max(...Object.keys(weightedCombosMap).map(parseFloat));
  const noopShortener = arr => arr.join(",");
  const shortener = format === RANGE_STRING_MONKER ? noopShortener :  shortenRange;

  return Object
    .entries(weightedCombosMap)
    // Get rid of any negative weights (shouldn't really be a thing?)
    .filter(([weight]) => weight > 0)
    // For each weight in the weight map, create a shorthand range string (if required) out of it's associated combos
    .map(([weight, combos]) => {
      if (!normalizeRangeString) return getWeightedRangeString(shortener(combos), parseFloat(weight));
      return getWeightedRangeString(shortener(combos), parseFloat(weight) / highestIncludedActionFrequency);
    })
    .join(",")
    .replace(/ /g, '');
}

const sortCards = cards => cards.sort((a, b) => rankCodes[b.rank] - rankCodes[a.rank]);

/**
 * Return the string representation of list of cards
 * Automatically sorts the cards by rank
 * @param [{rank: string, suit: string}] cards The array of cards
 * @returns string The cards represented as a string
 * @example
 * // returns AhAd
 * cardsToString([{rank: "A", suit: "h"}, {rank: "A", suit: "d"}])
 */
export const cardsToString = (cards) => {
  const sortedCards = sortCards(cards);
  return sortedCards.map(card => `${card.rank}${card.suit}`).join("");
}

/**
 * Return the combo representation of 2 of cards
 * Automatically sorts the cards by rank
 * @param [{rank: string, suit: string}] cards The array of cards
 * @returns string The combo string e.g. AKo, AKs, AA etc
 * @example
 * // returns AKo
 * cardsToString([{rank: "A", suit: "h"}, {rank: "K", suit: "d"}])
 */
export const cardsToComboString = (cards) => {
  const sortedCards = sortCards(cards);
  if (sortedCards[0].rank === sortedCards[1].rank) {
    return `${sortedCards[0].rank}${sortedCards[1].rank}`
  }
  const suffix = sortedCards[0].suit === sortedCards[1].suit ? "s" : "o";
  return `${sortedCards[0].rank}${sortedCards[1].rank}${suffix}`;
}

export const cardStringToCards = cardString => {
  if (cardString.length % 2 !== 0) throw Error("Invalid String");
  const cards = cardString
    .match(/.{1,2}/g)
    .map(i => ({rank: i[0], suit: i[1]}));
  return sortCards(cards);
}