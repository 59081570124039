export const FOLD = "Fold"
export const CHECK_CALL = "Check/Call"
export const RAISE = "Raise"
export const JAM = "Jam"
export const MIN = "Min Raise"

export const actions = {
  c: CHECK_CALL,
  f: FOLD,
  r: RAISE,
  j: JAM,
  min: MIN
}

export const trimGameTree = (gameTreePath) => {
  const firstNonFold = gameTreePath.findIndex(i => i[2] !== "f");
  return gameTreePath.slice(firstNonFold === -1 ? gameTreePath.length : firstNonFold);
}

export const actionIdToParts = (actionId) => {
  const position = actionId.slice(0,2);
  const actionString = actionId.slice(2);
  const action = actions[Object.keys(actions).find(i => actionString.indexOf(i) !== -1)];
  const size = action === RAISE ? parseInt(actionId.slice(3), 10) : undefined;
  return { position, action, size };
}

export const actionSorter = (a, b) => {
  const {action: aId} = actionIdToParts(a.slice(-1)[0]);
  const {action: bId} = actionIdToParts(b.slice(-1)[0]);
  // Sort folds to bottom
  if (aId === FOLD && bId !== FOLD) return 1;
  if (bId === FOLD && aId !== FOLD) return -1;
  // Sort fold to beneath ordinary fold
  if (aId === FOLD && bId === FOLD && a.length === 1 && b.length > 1) return -1;
  if (aId === FOLD && bId === FOLD && b.length === 1 && a.length > 1) return 1;
  return 0
}

export const gameTreeToPotDetails = (gameTreePath, {players, stack, ante, seats, straddles, bb = 1, sb = 0.5}) => {
  const blinds = seats.map((i, idx) => {
    if (idx === seats.length - (2 + straddles)) return sb
    if (idx === seats.length - (1 + straddles)) return bb
    if (idx === seats.length - (0 + straddles)) return 2*bb*straddles
    return 0
  })
  const startingTotals = seats.reduce((acc, next, idx) => {
    return {
      ...acc,
      [next]: blinds[idx]
    }
  }, {});
  
  let lastBet = bb;
  let tempPen = 0;
  const playerTotals = gameTreePath.reduce((acc, next) => {
    const {position, size, action} = actionIdToParts(next);
    if (action === FOLD) return acc;
    if (action === CHECK_CALL) return {
      ...acc,
      [position]: Math.max(...Object.values(acc))
    };
    if (action === JAM) {
      lastBet = Math.max(stack-ante/players, lastBet);
      return {
        ...acc,
        [position]: stack-ante/players
      }
    }
    if (action === MIN) {
        tempPen = Math.max(...Object.values(acc));
        lastBet = Math.max(2*tempPen-acc[position],2);
      return {
        ...acc,
        [position]: lastBet
      }
    }
    const currentPot = calculatePot(acc,ante);
    lastBet = calculateRaiseSizeInBB(currentPot, acc, size, position) || 0;
    return {
      ...acc,
      [position]: lastBet
    };
  }, startingTotals);

  return {
    playerTotals,
    pot: calculatePot(playerTotals, ante),
    stack,
    lastBet,
    ante,
    players
  }
}

const calculateRaiseSizeInBB = (pot, playerTotals, betSize, playerPosition) => (betSize/100)*(pot-playerTotals[playerPosition] + Math.max(...Object.values(playerTotals)) ) + Math.max(...Object.values(playerTotals));

export const calculatePot = (playerTotals, ante=0) => Object.values(playerTotals).reduce((a, b) => a + b, 0) + ante;

export const actionInBB = (actionId, {pot, playerTotals, stack, ante, players}) => {
  const {position, action, size} = actionIdToParts(actionId);
  if (action === FOLD) return 0;
  if (action === CHECK_CALL) return Math.max(...Object.values(playerTotals));
  if (action === JAM) return stack-(ante/Object.keys(playerTotals).length);
  if (action === MIN) return Math.max(Math.max(...Object.values(playerTotals))*2 - playerTotals[position],2);
  if (action === RAISE) return calculateRaiseSizeInBB(pot, playerTotals, size, position);
  return
};

export const actionIdToString = (actionId, compact=false) => {
  const { position, action, size } = actionIdToParts(actionId);
  if (compact) {
    return action;
  }
  return `${position} ${action}${size ? ` ${size}% pot` : ""}`;
}

export const orderOfAction = (gameTreePath, startPosition, seats) => {
  // Find who's still in the hand
  const stillInHand = seats.slice(seats.indexOf(startPosition)).filter(i => !gameTreePath.includes(`${i}f`));
  // If everyone has folded to the last seat then it's over
  if (stillInHand.length === 1) { 
    return [];
  }
  // Remove any actions from the game tree for players who've folded alredy
  const filteredGameTree = gameTreePath.filter(i => stillInHand.includes(actionIdToParts(i).position)).map(actionIdToParts);
  // Still at the start of the game tree or only folders
  if (!filteredGameTree.length) return stillInHand;

  let leftToAct = stillInHand;
  let matchedCurrentBet = [];

  // TODO: This assumes uniform stack sizes
  filteredGameTree.forEach(node => {
    const actor = node.position;
    const didRaise = [MIN, RAISE].includes(node.action);
    const didJam = JAM === node.action;
    if (didRaise) {
      leftToAct = leftToAct.filter(i => i !== actor).concat(matchedCurrentBet);
      matchedCurrentBet = [actor];
    } else if (didJam) {
      leftToAct = leftToAct.filter(i => i !== actor).concat(matchedCurrentBet);
      matchedCurrentBet = matchedCurrentBet.filter(i => i !== actor);
    } else {
      matchedCurrentBet.push(actor);
      leftToAct = leftToAct.filter(i => i !== actor);
    }
  });
  
  return leftToAct;
}

export const startPositionFromNodeId = (nodeId, seats) => {
  if (!nodeId) return seats[0];
  const actors = nodeId.split("/");
  const nonFolders = actors.filter(i => i[2] !== "f").map(i => i.substring(0, 2));
  return nonFolders.length > 0 ? nonFolders[0] : seats[actors[actors.length]];
}

export const isStillInHand = (gameTreePath, position, startPosition, seats) => 
  !(gameTreePath.indexOf(`${position}f`) !== -1 ||
    seats.slice(seats.indexOf(startPosition)).indexOf(position) === -1)

export const gameTreePathToNodeId = gameTreePath => gameTreePath.reduce((acc, next) => `${acc}/${next}`, "").replace(/^\/|\/$/g, '');

export const actorLastAction = (gameTreePath, position) => gameTreePath.slice().reverse().find(i => actionIdToParts(i).position === position)

export const isFold = actionId => actionId ? actionId[2] === "f" : false;

export const isRaise = (actionId, includeJams=false) => {
  const raiseActions = ["r", "m"];
  if (includeJams) raiseActions.push("j");
  return actionId ? raiseActions.includes(actionId[2]) : false;
}
