import localforage from "localforage";

const KEY = "handHistory";

export const getHandHistory = (count = 100) => localforage.getItem(KEY)
  .then(res => (res || []).slice(0, count));

export const storeHand = (hand) => {
  return getHandHistory(200)
    .then(value => {
      return localforage.setItem(KEY,  [hand].concat(value || []).slice(0, 200));
    })
    .catch(err => {
      console.error(err);
    })
}
